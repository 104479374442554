import React, {useEffect, useRef, useState} from 'react';
import style from './Header.module.css'
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Link, useLocation, useNavigate} from "react-router-dom";
import profile from '../../assets/img/profile.svg'
import './../../Pages/WelcomePage/WelcomePage.css'
import {Dropdown, OverlayTrigger, Popover} from "react-bootstrap";
import i18n from "i18next";
import Loader from "../Loader/Loader";
import changeLanguage from "../../Api/ChangeLanguage";
import Logout from "../../Api/Logout";

const Header = ({userName}) => {
    const dispatch = useDispatch()
    const {t} = useTranslation();
    const location = useLocation()
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    const headerContainerRef = useRef(null);
    const [shared, setShared] = useState(false)
    const [aliasData, setAliasData] = useState({})
    const [loader,setLoader] = useState(false)


    let redirectUrl
    const token = localStorage.getItem("auth_token")
    if (process.env.NODE_ENV === 'production') {
        redirectUrl = i18n.language === "en" ? `${process.env.REACT_APP_PROD_LINK}todo` : `${process.env.REACT_APP_PROD_LINK}${i18n.language}/todo`
    } else {
        redirectUrl = i18n.language === "en" ? `${process.env.REACT_APP_DEV_LINK}todo` : `${process.env.REACT_APP_DEV_LINK}${i18n.language}/todo`
    }
    useEffect(() => {
        (async () => {
            if (!aliasData){
                const isSharedInspection = location.pathname.includes('/inspections/shared');
                const isSharedOnlineInspection = location.pathname.includes('online-inspections/shared');

                if (isSharedInspection || isSharedOnlineInspection) {
                    setLoader(true);
                    setShared(true);

                    const segments = location.pathname.split('/');
                    const alias = segments[segments.length - 1];

                    const apiEndpoint = isSharedInspection
                        ? `https://api.carinspect.org/inspections/get-by-alias/${alias}`
                        : `https://api.carinspect.org/online-inspections/get-by-alias/${alias}`;

                    try {
                        const response = await fetch(apiEndpoint);
                        console.log('response', response);

                        if (response.status === 404) {
                            setAliasData(null);
                        } else if (!response.ok) {
                            console.log(`HTTP error! status: ${response.status}`);
                            setAliasData(null);
                        } else {
                            const result = await response.json();
                            setAliasData(result);
                        }
                    } catch (error) {
                        console.log("Произошла ошибка при запросе:", error);
                        setAliasData(null);
                    }

                    setLoader(false);
                }
            }

        })();
    }, [location.pathname]);

    useEffect(() => {



        const handleResize = () => {
            if (window.innerWidth > 992) {
                setIsOpen(false)
            }
        }
        const handleClickOutside = (event) => {
            if (headerContainerRef.current && !headerContainerRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        window.addEventListener('resize', handleResize)


        return () => {
            window.removeEventListener('resize', handleResize)
            document.removeEventListener('click', handleClickOutside);

        }
    }, [])


    const languageTranslations = {
        en: 'English',
        ru: 'Русский',
        uk: 'Українська',
    };

    if(loader){
        return <Loader/>
    }
    return (
        <div className={style.header_container} style={shared && aliasData?.user?.company ? {height:130}:null}>
            <div className={"wrapper"}>
                <div className={'header_between'} style={shared && aliasData?.user?.company? {height:130}:null}>
                    {shared?
                        aliasData?.user?.company?.logo ? <img src={aliasData?.user?.company?.logo} style={{width:100}} alt=""/> : null
                        :
                    <div onClick={() => navigate(i18n.language === "en" ? `/` : `/${i18n.language}/`)}
                         className={style.logo_text}>InspectInfo
                    </div>
                    }
                    {shared?
                        <div className={'company_info_container'}>
                            <div className={'company_shared_text name'}>
                                {aliasData?.user?.company?.name}
                            </div>
                            <div className={'company_shared_text'}>
                                <div className={'company_phone_person_container'}>{aliasData?.user?.company?.contact_person} <span className={aliasData?.user?.company?.contact_person ? 'company_shared_text custom_phone_shared' : 'company_shared_text'}>  {aliasData?.user?.company?.phone_number} </span></div>
                            </div>
                            <div className={'company_shared_text address_company_text'}>
                                {aliasData?.user?.company?.address}
                            </div>
                        </div>
                        :
                    <div>
                        <nav className={style.menu_container}>
                            {token ?
                                <ul className={style.menu_ul}>
                                    <li style={location.pathname.includes('/todo') ? {borderBottom: '2px solid white'} : {borderBottom: "none"}}
                                        className={style.menu_item}><Link
                                        to={i18n.language === "en" ? `/todo/page/1` : `/${i18n.language}/todo/page/1`}>{t("todoScreen")}</Link>
                                    </li>
                                    <li style={location.pathname.includes('/inspections') ? {borderBottom: '2px solid white'} : {borderBottom: "none"}}
                                        className={style.menu_item}><Link
                                        to={i18n.language === "en" ? `/inspections/page/1` : `/${i18n.language}/inspections/page/1`}>{t("inspectionListScreen")}</Link>
                                    </li>
                                    <li style={location.pathname.includes('/advertisement') ? {borderBottom: '2px solid white'} : {borderBottom: "none"}}
                                        className={style.menu_item}><Link
                                        to={i18n.language === "en" ? `/advertisement/page/1` : `/${i18n.language}/advertisement/page/1`}>{t("adListScreen")}</Link>
                                    </li>
                                </ul>
                                : null}
                        </nav>
                    </div>
                    }
                    {token ?
                        <OverlayTrigger
                            placement={"bottom"}
                            trigger="click"
                            rootClose={true}
                            overlay={
                                <Popover id="popover-trigger-focus" style={{maxWidth: 1000}}>
                                    <Popover.Body style={{padding: 10, maxWidth: 1000}}>
                                        <div className={""}>
                                            <div
                                                className={"text-center mb-10 custom-font-popover"}>{t("welcome")}, {userName}{userName && "!"}</div>
                                            <div
                                                style={{borderBottom: "1px solid rgb(30, 211, 66)", marginBottom: 10}}/>
                                            <div onClick={() => {
                                                navigate(i18n.language === "en" ? `/profile` : `/${i18n.language}/profile`)
                                                setShared(false)
                                                setIsOpen(false)

                                            }}
                                                 className={"custom-font-popover mb-10 popover-item"}>
                                                <svg className="mr-10" width={21} viewBox="0 0 24 24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
                                                       strokeLinejoin="round"></g>
                                                    <g id="SVGRepo_iconCarrier">
                                                        <path
                                                            d="M6 8V5C6 4.44772 6.44772 4 7 4H17C17.5523 4 18 4.44772 18 5V19C18 19.5523 17.5523 20 17 20H7C6.44772 20 6 19.5523 6 19V16M6 12H13M13 12L10.5 9.77778M13 12L10.5 14.2222"
                                                            stroke="#000000" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                    </g>
                                                </svg>
                                                {t("profile.toCabinet")}

                                            </div>
                                            <div className={'mb-2 custom-font-popover popover-item d-flex align-items-center'}
                                                 onClick={()=>{navigate(i18n.language === "en" ? `/company` : `/${i18n.language}/company`); setIsOpen(false) ;  setShared(false)
                                                 }}>
                                                <svg width={18} style={{marginRight: 10}} fill="#000000" viewBox="0 0 50 50"
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     xmlnsXlink="http://www.w3.org/1999/xlink">
                                                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                    <g id="SVGRepo_iconCarrier">
                                                        <path
                                                            d="M8 2L8 6L4 6L4 48L46 48L46 14L30 14L30 6L26 6L26 2 Z M 10 4L24 4L24 8L28 8L28 46L19 46L19 39L15 39L15 46L6 46L6 8L10 8 Z M 10 10L10 12L12 12L12 10 Z M 14 10L14 12L16 12L16 10 Z M 18 10L18 12L20 12L20 10 Z M 22 10L22 12L24 12L24 10 Z M 10 15L10 19L12 19L12 15 Z M 14 15L14 19L16 19L16 15 Z M 18 15L18 19L20 19L20 15 Z M 22 15L22 19L24 19L24 15 Z M 30 16L44 16L44 46L30 46 Z M 32 18L32 20L34 20L34 18 Z M 36 18L36 20L38 20L38 18 Z M 40 18L40 20L42 20L42 18 Z M 10 21L10 25L12 25L12 21 Z M 14 21L14 25L16 25L16 21 Z M 18 21L18 25L20 25L20 21 Z M 22 21L22 25L24 25L24 21 Z M 32 22L32 24L34 24L34 22 Z M 36 22L36 24L38 24L38 22 Z M 40 22L40 24L42 24L42 22 Z M 32 26L32 28L34 28L34 26 Z M 36 26L36 28L38 28L38 26 Z M 40 26L40 28L42 28L42 26 Z M 10 27L10 31L12 31L12 27 Z M 14 27L14 31L16 31L16 27 Z M 18 27L18 31L20 31L20 27 Z M 22 27L22 31L24 31L24 27 Z M 32 30L32 32L34 32L34 30 Z M 36 30L36 32L38 32L38 30 Z M 40 30L40 32L42 32L42 30 Z M 10 33L10 37L12 37L12 33 Z M 14 33L14 37L16 37L16 33 Z M 18 33L18 37L20 37L20 33 Z M 22 33L22 37L24 37L24 33 Z M 32 34L32 36L34 36L34 34 Z M 36 34L36 36L38 36L38 34 Z M 40 34L40 36L42 36L42 34 Z M 32 38L32 40L34 40L34 38 Z M 36 38L36 40L38 40L38 38 Z M 40 38L40 40L42 40L42 38 Z M 10 39L10 44L12 44L12 39 Z M 22 39L22 44L24 44L24 39 Z M 32 42L32 44L34 44L34 42 Z M 36 42L36 44L38 44L38 42 Z M 40 42L40 44L42 44L42 42Z"></path>
                                                    </g>
                                                </svg>
                                                {t('profile.company')}
                                            </div>
                                            <div
                                                className={"d-flex align-items-center justify-content-between text-nowrap"}>
                                                <div>
                                                    <div className={"custom-font-popover "}>
                                                        <svg className="mr-10" width={18} fill="#737171" version="1.1"
                                                             id="XMLID_275_" xmlns="http://www.w3.org/2000/svg"
                                                             xmlns="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"
                                                             xml="preserve">
                                                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
                                                               strokeLinejoin="round"></g>
                                                            <g id="SVGRepo_iconCarrier">
                                                                <g id="language">
                                                                    <g>
                                                                        <path
                                                                            d="M12,24C5.4,24,0,18.6,0,12S5.4,0,12,0s12,5.4,12,12S18.6,24,12,24z M9.5,17c0.6,3.1,1.7,5,2.5,5s1.9-1.9,2.5-5H9.5z M16.6,17c-0.3,1.7-0.8,3.3-1.4,4.5c2.3-0.8,4.3-2.4,5.5-4.5H16.6z M3.3,17c1.2,2.1,3.2,3.7,5.5,4.5c-0.6-1.2-1.1-2.8-1.4-4.5H3.3 z M16.9,15h4.7c0.2-0.9,0.4-2,0.4-3s-0.2-2.1-0.5-3h-4.7c0.2,1,0.2,2,0.2,3S17,14,16.9,15z M9.2,15h5.7c0.1-0.9,0.2-1.9,0.2-3 S15,9.9,14.9,9H9.2C9.1,9.9,9,10.9,9,12C9,13.1,9.1,14.1,9.2,15z M2.5,15h4.7c-0.1-1-0.1-2-0.1-3s0-2,0.1-3H2.5C2.2,9.9,2,11,2,12 S2.2,14.1,2.5,15z M16.6,7h4.1c-1.2-2.1-3.2-3.7-5.5-4.5C15.8,3.7,16.3,5.3,16.6,7z M9.5,7h5.1c-0.6-3.1-1.7-5-2.5-5 C11.3,2,10.1,3.9,9.5,7z M3.3,7h4.1c0.3-1.7,0.8-3.3,1.4-4.5C6.5,3.3,4.6,4.9,3.3,7z"></path>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                        {t("profile.changeLang")}:
                                                    </div>
                                                </div>
                                                <Dropdown
                                                    onMouseEnter={() => document.getElementById('hover-dropdown').classList.add('show')}
                                                    onMouseLeave={() => document.getElementById('hover-dropdown').classList.remove('show')}
                                                >
                                                    <Dropdown.Toggle
                                                        className={"custom-popover-link"}
                                                        style={{
                                                            textTransform: 'none',
                                                            padding: 5,
                                                            marginLeft: 5,
                                                            backgroundColor: 'transparent',
                                                            color: 'black',
                                                            border: 'none',
                                                            borderBottom: "1px solid #D4D4D4",
                                                            borderRadius: 0,
                                                            outline: "none",
                                                            boxShadow: "none",
                                                        }}
                                                        variant="light"
                                                        id="hover-dropdown"
                                                    >
                                                        {languageTranslations[i18n.language]}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {i18n.languages.map((language) => {
                                                            if (language !== i18n.language) {
                                                                return (
                                                                    <Dropdown.Item
                                                                        onClick={() => changeLanguage(language, navigate)}
                                                                        key={language}
                                                                        href="#"
                                                                    >

                                                                        {languageTranslations[language]}
                                                                    </Dropdown.Item>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div className={"mt-10 custom-font-popover popover-item"}
                                             style={{color: 'red'}}
                                             onClick={() => {Logout(navigate, dispatch); setShared(false)}}>
                                            <svg width={18} className={"mr-10"} fill="#ff0000" viewBox="0 0 24 24"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
                                                   strokeLinejoin="round"></g>
                                                <g id="SVGRepo_iconCarrier">
                                                    <path d="M16 13v-2H7V8l-5 4 5 4v-3z"></path>
                                                    <path
                                                        d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z"></path>
                                                </g>
                                            </svg>
                                            {t("profile.logout")}</div>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <div className={style.link_to_profile} style={{display: 'flex'}}>
                                <img src={profile} className={style.profile_img} alt=""/>
                                <div style={{cursor: "pointer"}}
                                     className={style.menu_item_profile}>{t("profileScreen")}</div>
                            </div>
                        </OverlayTrigger>
                        :
                        <div className={"d-flex align-items-center"}>
                            <svg viewBox="0 0 24 24" width={21} xmlns="http://www.w3.org/2000/svg" fill="#ffffff" stroke="#ffffff">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path fill="none" stroke="#ffffff" stroke-width="2"
                                          d="M12,23 C18.0751322,23 23,18.0751322 23,12 C23,5.92486775 18.0751322,1 12,1 C5.92486775,1 1,5.92486775 1,12 C1,18.0751322 5.92486775,23 12,23 Z M12,23 C15,23 16,18 16,12 C16,6 15,1 12,1 C9,1 8,6 8,12 C8,18 9,23 12,23 Z M2,16 L22,16 M2,8 L22,8"></path>
                                </g>
                            </svg>
                            <Dropdown>
                                <Dropdown.Toggle  style={{paddingLeft:5}} className="link_navbar custom_changeLang_btn" id="dropdown-basic">
                                    {languageTranslations[i18n.language]}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {i18n.languages.map((language) => {
                                        if (language !== i18n.language) {
                                            return (
                                                <Dropdown.Item
                                                    onClick={() => changeLanguage(language, navigate)}
                                                    key={language}
                                                    href="#"
                                                >

                                                    {languageTranslations[language]}
                                                </Dropdown.Item>
                                            );
                                        }
                                        return null;
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    }
                    {token ? <div className='burger_menu_container'>
                        <div id="nav-icon1" className={isOpen ? 'open' : ''} onClick={() => setIsOpen(!isOpen)}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div> : null}
                </div>
            </div>
            {isOpen && token && <div className='burger_menu_dropdown'
                                     style={shared?{top: 120, background: 'linear-gradient(to right, #11B64B, #44AB91)'}: {top: 69, background: 'linear-gradient(to right, #11B64B, #44AB91)'}}>
                <ul className='burger_menu_dropdown_ul'>
                    <li className="burger_menu_dropdown_li"><Link className="link_navbar"
                                                                  onClick={() => {setIsOpen(false)
                                                                      setShared(false)}}
                                                                  to={i18n.language === "en" ? `/todo` : `/${i18n.language}/todo`}>{t("todoScreen")}</Link>
                    </li>
                    <li className="burger_menu_dropdown_li"><Link className="link_navbar"
                                                                  onClick={() => {setIsOpen(false);  setShared(false)}}
                                                                  to={i18n.language === "en" ? `/inspections` : `/${i18n.language}/inspections`}>{t("inspectionListScreen")}</Link>
                    </li>
                    <li className="burger_menu_dropdown_li"><Link className="link_navbar"
                                                                  onClick={() => {setIsOpen(false);  setShared(false)}}
                                                                  to={i18n.language === "en" ? `/advertisement` : `/${i18n.language}/advertisement`}>{t("adListScreen")}</Link>
                    </li>
                    <li className="burger_menu_dropdown_li">
                        <OverlayTrigger
                            rootClose={true}
                            placement={"bottom"}
                            trigger="click"
                            overlay={
                                <Popover id="popover-trigger-focus" style={{maxWidth: 1000}}>

                                    <Popover.Body style={{padding: 10, maxWidth: 1000}}>
                                        <div className={""}>
                                            <div
                                                className={"text-center mb-10 custom-font-popover"}>{t("welcome")}, {userName}{userName && "!"}</div>
                                            <div
                                                style={{borderBottom: "1px solid rgb(30, 211, 66)", marginBottom: 10}}/>
                                            <div onClick={() => {
                                                navigate(i18n.language === "en" ? `/profile` : `/${i18n.language}/profile`);
                                                setShared(false)
                                                setIsOpen(false)

                                            }}
                                                 className={"custom-font-popover mb-10 popover-item"}>
                                                <svg className="mr-10" width={18} viewBox="0 0 24 24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
                                                       strokeLinejoin="round"></g>
                                                    <g id="SVGRepo_iconCarrier">
                                                        <path
                                                            d="M6 8V5C6 4.44772 6.44772 4 7 4H17C17.5523 4 18 4.44772 18 5V19C18 19.5523 17.5523 20 17 20H7C6.44772 20 6 19.5523 6 19V16M6 12H13M13 12L10.5 9.77778M13 12L10.5 14.2222"
                                                            stroke="#000000" strokeLinecap="round"
                                                            strokeLinejoin="round"></path>
                                                    </g>
                                                </svg>
                                                {t("profile.toCabinet")}
                                            </div>
                                            <div className={'mb-2 custom-font-popover popover-item d-flex align-items-center'}
                                                 onClick={()=>{navigate(i18n.language === "en" ? `/company` : `/${i18n.language}/company`); setIsOpen(false);  setShared(false)
                                                 }}
                                            >
                                                <svg width={18} style={{marginRight: 10}} fill="#000000" viewBox="0 0 50 50"
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     xmlnsXlink="http://www.w3.org/1999/xlink">
                                                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                    <g id="SVGRepo_iconCarrier">
                                                        <path
                                                            d="M8 2L8 6L4 6L4 48L46 48L46 14L30 14L30 6L26 6L26 2 Z M 10 4L24 4L24 8L28 8L28 46L19 46L19 39L15 39L15 46L6 46L6 8L10 8 Z M 10 10L10 12L12 12L12 10 Z M 14 10L14 12L16 12L16 10 Z M 18 10L18 12L20 12L20 10 Z M 22 10L22 12L24 12L24 10 Z M 10 15L10 19L12 19L12 15 Z M 14 15L14 19L16 19L16 15 Z M 18 15L18 19L20 19L20 15 Z M 22 15L22 19L24 19L24 15 Z M 30 16L44 16L44 46L30 46 Z M 32 18L32 20L34 20L34 18 Z M 36 18L36 20L38 20L38 18 Z M 40 18L40 20L42 20L42 18 Z M 10 21L10 25L12 25L12 21 Z M 14 21L14 25L16 25L16 21 Z M 18 21L18 25L20 25L20 21 Z M 22 21L22 25L24 25L24 21 Z M 32 22L32 24L34 24L34 22 Z M 36 22L36 24L38 24L38 22 Z M 40 22L40 24L42 24L42 22 Z M 32 26L32 28L34 28L34 26 Z M 36 26L36 28L38 28L38 26 Z M 40 26L40 28L42 28L42 26 Z M 10 27L10 31L12 31L12 27 Z M 14 27L14 31L16 31L16 27 Z M 18 27L18 31L20 31L20 27 Z M 22 27L22 31L24 31L24 27 Z M 32 30L32 32L34 32L34 30 Z M 36 30L36 32L38 32L38 30 Z M 40 30L40 32L42 32L42 30 Z M 10 33L10 37L12 37L12 33 Z M 14 33L14 37L16 37L16 33 Z M 18 33L18 37L20 37L20 33 Z M 22 33L22 37L24 37L24 33 Z M 32 34L32 36L34 36L34 34 Z M 36 34L36 36L38 36L38 34 Z M 40 34L40 36L42 36L42 34 Z M 32 38L32 40L34 40L34 38 Z M 36 38L36 40L38 40L38 38 Z M 40 38L40 40L42 40L42 38 Z M 10 39L10 44L12 44L12 39 Z M 22 39L22 44L24 44L24 39 Z M 32 42L32 44L34 44L34 42 Z M 36 42L36 44L38 44L38 42 Z M 40 42L40 44L42 44L42 42Z"></path>
                                                    </g>
                                                </svg>
                                                {t('profile.company')}
                                            </div>
                                            {/*<div*/}
                                            {/*    className={"custom-font-popover d-flex justify-content-between align-items-center"}>*/}
                                            {/*    <div>*/}
                                            {/*        <svg width={18} className={"mr-10"} viewBox="0 0 24 24" fill="none"*/}
                                            {/*             xmlns="http://www.w3.org/2000/svg">*/}
                                            {/*            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>*/}
                                            {/*            <g id="SVGRepo_tracerCarrier" strokeLinecap="round"*/}
                                            {/*               strokeLinejoin="round"></g>*/}
                                            {/*            <g id="SVGRepo_iconCarrier">*/}
                                            {/*                <path*/}
                                            {/*                    d="M9.15316 5.40838C10.4198 3.13613 11.0531 2 12 2C12.9469 2 13.5802 3.13612 14.8468 5.40837L15.1745 5.99623C15.5345 6.64193 15.7144 6.96479 15.9951 7.17781C16.2757 7.39083 16.6251 7.4699 17.3241 7.62805L17.9605 7.77203C20.4201 8.32856 21.65 8.60682 21.9426 9.54773C22.2352 10.4886 21.3968 11.4691 19.7199 13.4299L19.2861 13.9372C18.8096 14.4944 18.5713 14.773 18.4641 15.1177C18.357 15.4624 18.393 15.8341 18.465 16.5776L18.5306 17.2544C18.7841 19.8706 18.9109 21.1787 18.1449 21.7602C17.3788 22.3417 16.2273 21.8115 13.9243 20.7512L13.3285 20.4768C12.6741 20.1755 12.3469 20.0248 12 20.0248C11.6531 20.0248 11.3259 20.1755 10.6715 20.4768L10.0757 20.7512C7.77268 21.8115 6.62118 22.3417 5.85515 21.7602C5.08912 21.1787 5.21588 19.8706 5.4694 17.2544L5.53498 16.5776C5.60703 15.8341 5.64305 15.4624 5.53586 15.1177C5.42868 14.773 5.19043 14.4944 4.71392 13.9372L4.2801 13.4299C2.60325 11.4691 1.76482 10.4886 2.05742 9.54773C2.35002 8.60682 3.57986 8.32856 6.03954 7.77203L6.67589 7.62805C7.37485 7.4699 7.72433 7.39083 8.00494 7.17781C8.28555 6.96479 8.46553 6.64194 8.82547 5.99623L9.15316 5.40838Z"*/}
                                            {/*                    stroke="#000000" strokeWidth="1.5"></path>*/}
                                            {/*            </g>*/}
                                            {/*        </svg>*/}
                                            {/*        {t("profile.tariff")}*/}
                                            {/*    </div>*/}
                                            {/*    <div className={'custom-popover-link ml-20 '}> {t("profile.tariff_default")}*/}
                                            {/*        <svg width={20} viewBox="0 0 24 24" fill="#737171"*/}
                                            {/*             xmlns="http://www.w3.org/2000/svg">*/}
                                            {/*            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>*/}
                                            {/*            <g id="SVGRepo_tracerCarrier" strokeLinecap="round"*/}
                                            {/*               strokeLinejoin="round"></g>*/}
                                            {/*            <g id="SVGRepo_iconCarrier">*/}
                                            {/*                <path d="M10 7L15 12L10 17" stroke="#000000" strokeWidth="1.5"*/}
                                            {/*                      strokeLinecap="round" strokeLinejoin="round"></path>*/}
                                            {/*            </g>*/}
                                            {/*        </svg>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div
                                                className={"d-flex align-items-center justify-content-between text-nowrap mt-2"}>
                                                <div>
                                                    <div className={"custom-font-popover "}>
                                                        <svg className="mr-10" width={18} fill="#737171" version="1.1"
                                                             id="XMLID_275_" xmlns="http://www.w3.org/2000/svg"
                                                             xmlns="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"
                                                             xml="preserve">
                                                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
                                                               strokeLinejoin="round"></g>
                                                            <g id="SVGRepo_iconCarrier">
                                                                <g id="language">
                                                                    <g>
                                                                        <path
                                                                            d="M12,24C5.4,24,0,18.6,0,12S5.4,0,12,0s12,5.4,12,12S18.6,24,12,24z M9.5,17c0.6,3.1,1.7,5,2.5,5s1.9-1.9,2.5-5H9.5z M16.6,17c-0.3,1.7-0.8,3.3-1.4,4.5c2.3-0.8,4.3-2.4,5.5-4.5H16.6z M3.3,17c1.2,2.1,3.2,3.7,5.5,4.5c-0.6-1.2-1.1-2.8-1.4-4.5H3.3 z M16.9,15h4.7c0.2-0.9,0.4-2,0.4-3s-0.2-2.1-0.5-3h-4.7c0.2,1,0.2,2,0.2,3S17,14,16.9,15z M9.2,15h5.7c0.1-0.9,0.2-1.9,0.2-3 S15,9.9,14.9,9H9.2C9.1,9.9,9,10.9,9,12C9,13.1,9.1,14.1,9.2,15z M2.5,15h4.7c-0.1-1-0.1-2-0.1-3s0-2,0.1-3H2.5C2.2,9.9,2,11,2,12 S2.2,14.1,2.5,15z M16.6,7h4.1c-1.2-2.1-3.2-3.7-5.5-4.5C15.8,3.7,16.3,5.3,16.6,7z M9.5,7h5.1c-0.6-3.1-1.7-5-2.5-5 C11.3,2,10.1,3.9,9.5,7z M3.3,7h4.1c0.3-1.7,0.8-3.3,1.4-4.5C6.5,3.3,4.6,4.9,3.3,7z"></path>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                        {t("profile.changeLang")}:
                                                    </div>
                                                </div>
                                                <Dropdown
                                                    onMouseEnter={() => document.getElementById('hover-dropdown').classList.add('show')}
                                                    onMouseLeave={() => document.getElementById('hover-dropdown').classList.remove('show')}>
                                                    <Dropdown.Toggle className={"custom-popover-link"} style={{
                                                        textTransform: 'none',
                                                        padding: 5,
                                                        marginLeft: 5,
                                                        backgroundColor: 'transparent',
                                                        color: 'black',
                                                        border: 'none',
                                                        borderBottom: "1px solid #D4D4D4",
                                                        borderRadius: 0,
                                                        outline: "none",
                                                        boxShadow: "none",
                                                    }} variant="light"
                                                                     id="hover-dropdown">

                                                        {languageTranslations[i18n.language]}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {i18n.languages.map((language) => {
                                                            if (language !== i18n.language) {
                                                                return (
                                                                    <Dropdown.Item
                                                                        onClick={() => changeLanguage(language, navigate)}
                                                                        key={language}
                                                                        href="#"
                                                                    >
                                                                        {languageTranslations[language]}
                                                                    </Dropdown.Item>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div className={"mt-10 custom-font-popover popover-item"}
                                             style={{color: 'red'}}
                                             onClick={() => {Logout(navigate, dispatch);  setShared(false)}}>
                                            <svg width={18} className={"mr-10"} fill="#ff0000" viewBox="0 0 24 24"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
                                                   strokeLinejoin="round"></g>
                                                <g id="SVGRepo_iconCarrier">
                                                    <path d="M16 13v-2H7V8l-5 4 5 4v-3z"></path>
                                                    <path
                                                        d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z"></path>
                                                </g>
                                            </svg>
                                            {t("profile.logout")}</div>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <a className="link_navbar " style={{cursor: "pointer"}}
                               onClick={(e) => e.stopPropagation()}>{t("profileScreen")}</a>
                        </OverlayTrigger>
                    </li>
                </ul>
            </div>}
        </div>

    );
};

export default Header;
