import i18n from "i18next";

const changeLanguage = (language, navigate) => {
    const currentUrl = window.location.href;
    const currentLang = i18n.language;

    const cleanedUrl = currentUrl.replace(`${window.location.origin}`, "");

    const pathAfterLang = cleanedUrl.replace(`/${currentLang}/`, "");

    const cleanedPath = pathAfterLang.replace(/^\/|\/$/g, '');

    if (language === "en") {
        const newUrl = `/${cleanedPath}`;
        navigate(newUrl)
    } else {
        const langPrefix = `/${language}/`;
        const newUrl = langPrefix + cleanedPath;
        navigate(newUrl)
    }

    i18n.changeLanguage(language);
    localStorage.setItem("language", language);
};

export default changeLanguage