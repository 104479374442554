import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export const WebViewMainPage = ({}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()


    return (
        <div className="container WebViewMainPage">
            <h1>{t("WebViewMainPage.how_to_create")}</h1>

            <div className="step">
                <div className="step-number">{t("WebViewMainPage.step")} 1: {t("WebViewMainPage.step1_title")}</div>
                <div className="step-description">{t("WebViewMainPage.step1_text")}</div>
            </div>

            <div className="step">
                <div className="step-number">{t("WebViewMainPage.step")} 2: {t("WebViewMainPage.step2_title")}</div>
                <div className="step-description">{t("WebViewMainPage.step2_text")}</div>
            </div>

            <div className="step">
                <div className="step-number">{t("WebViewMainPage.step")} 3: {t("WebViewMainPage.step3_title")}</div>
                <div className="step-description">{t("WebViewMainPage.step3_text")}</div>
            </div>

            <div className="step">
                <div className="step-number">{t("WebViewMainPage.step")} 4: {t("WebViewMainPage.step4_title")}</div>
                <div className="step-description">{t("WebViewMainPage.step4_text")}</div>
            </div>

            <div className="note">
                {t("WebViewMainPage.note")}
            </div>

            <details>
                <summary>{t("WebViewMainPage.available_sites")}</summary>
                <ul>
                    <li>aldcarmarket.com</li>
                    <li>arval.com</li>
                    <li>athloncarplaza.com</li>
                    <li>auto1.com</li>
                    <li>auto24.lv</li>
                    <li>autohelperbot.com</li>
                    <li>autobid.de</li>
                    <li>autoscout24.com</li>
                    <li>autorola.de</li>
                    <li>autoplius.lt</li>
                    <li>car-auctions.de</li>
                    <li>carnext.com</li>
                    <li>kbcremarketing.be</li>
                    <li>kvd.se</li>
                    <li>lacentrale.fr</li>
                    <li>mobile.de</li>
                    <li>myauto.ge</li>
                    <li>openlane.eu</li>
                    <li>olx.ua</li>
                    <li>otomoto.pl</li>
                    <li>rst.ua</li>
                    <li>sauto.cz</li>
                    <li>uto.ria.com</li>
                    <li>vwfs.de</li>
                </ul>
            </details>
        </div>
    )
}