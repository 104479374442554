import React, {useEffect, useState} from 'react';
import logo from './images/logo.svg';
import arrowDown from './images/arrow-down.png';
import bannerUser from './images/banner-user.png';
import bannerMobile from './images/banner-mobile.png';
import bannerShape from './images/banner-shape.png';
import sponser1 from './images/sponser-1.png';
import sponser2 from './images/sponser-2.png';
import sponser3 from './images/sponser-3.png';
import sponser4 from './images/sponser-4.png';
import sponser5 from './images/sponser-5.png';
import features1 from './images/features-1.png';
import features2 from './images/features-2.png';
import features3 from './images/features-3.png';
import serviceMobile1 from './images/service-mobile-1.png';
import check from './images/check.svg';
import checkPng from './images/check.png';
import serviceMobile2 from './images/service-mobile-2.png';
import playMobile from './images/play-mobile.png';
import playIcon from './images/play-icon.svg';
import reviewThumb from './images/review-thumb.png';
import testimonialIcon1 from './images/testimonial-icon-1.png';
import testimonialIcon2 from './images/testimonial-icon-2.png';
import testimonialIcon3 from './images/testimonial-icon-3.png';
import store1 from './images/store-1.png';
import store2 from './images/store-2.png';
import ctaMobile from './images/cta-mobile.png';
import logo2 from './images/logo-2.png';
import {useTranslation} from "react-i18next";
import Login from "../../Api/Login";
import {useLocation, useNavigate} from "react-router-dom";
import i18n from "i18next";
import {useSelector} from "react-redux";
import {Dropdown} from "react-bootstrap";
import changeLanguage from "../../Api/ChangeLanguage";
import extension from "../../assets/img/store_badges/extension_icon.webp";
import appstore from "../../assets/img/store_badges/appstore.webp";
import googleplay from "../../assets/img/store_badges/googleplay.webp";
// import HeaderMain from "../Header/HeaderMain";

const Main = () => {
    const [authToken, setAuthToken] = useState(() => localStorage.getItem('auth_token'));
    const {t, i18n} = useTranslation();
    const user_is_auth = useSelector(state => state.user_is_auth)
    let redirectUrl;
    const location = useLocation()
    const navigate = useNavigate();

    const supportedLanguages = ['en', 'ru', 'uk'];
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            redirectUrl =`${process.env.REACT_APP_PROD_LINK}`
        }else{
            redirectUrl = `${process.env.REACT_APP_DEV_LINK}`
        }
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get('token');
        if (token !== null && token !== undefined && token !== '') {
            localStorage.setItem("auth_token", token);
            setAuthToken(token)
            if (redirectUrl.startsWith('http')) {
                window.location.href = redirectUrl;
            } else {
                navigate(redirectUrl);
            }
        }
        const pathParts = location.pathname.split('/');
        const possibleLang = pathParts[1];
        const isSupportedLanguage = supportedLanguages.includes(possibleLang);

        if (!isSupportedLanguage) {
            i18n.changeLanguage('en');
        } else {
            i18n.changeLanguage(possibleLang);
        }
    }, []);

    const languageTranslations = {
        en: 'English',
        ru: 'Русский',
        uk: 'Українська',
    };
    const handleLogin = async () => {
        const url = await Login(redirectUrl)
        try {
            window.open(url, '_self')
        } catch (e) {
            console.log(e)
        }
    }
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);

        const currentPath = location.pathname.replace(/^\/(en|ru|uk)/, '');

        if (language === 'en') {
            navigate(`${currentPath}` || '/');
        } else {
            navigate(`/${language}${currentPath}`);
        }

    };
    return (
            <div cz-shortcut-listen="true" className={'cl-1'}>

                <div className="header-one header-sticky " >
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-md-6 col-sm-7 d-flex justify-content-center">
                                <div className="header-one__logo">
                                    <a href="#" className={'features__title a-no-style fs-3 d-flex color-white'}><img
                                        src={logo} className={'me-2'} width={45} alt=""/><span className={'logo-text text-white'}>InspectInfo</span></a>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5 col-sm-4">
                                <div className="header-one__menu">

                                    <div
                                        className="collapse show navbar-collapse sub-menu-bar main-nav__main-navigation stellarnav light top desktop">
                                        <a  className="menu-toggle full"><span
                                            className="bars"><span></span><span></span><span></span></span> </a>
                                        <ul className="main-nav__navigation-box ">
                                            <li className="nav-item dropdown has-sub">
                                                <a className="nav-link"
                                                   href="#main">{t('home')} </a>
                                                <a className="dd-toggle" ><span
                                                    className="icon-plus"></span></a></li>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link"   href="#service">{t('service')}</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link"   href="#help">{t('help')}</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link">{t('contact')}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-7 col-sm-7 d-lg-block d-none">
                                <div className="header-one__btn">
                                    <ul className="list d-flex justify-content-center align-items-center">
                                        <div className="dropdown">

                                            <Dropdown>
                                                <Dropdown.Toggle className="link_navbar custom_changeLang_btn " id="dropdown-basic">
                                                    {languageTranslations[i18n.language]}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {i18n.languages.map((language) => {
                                                        if (language !== i18n.language) {
                                                            return (
                                                                <Dropdown.Item
                                                                    onClick={() => changeLanguage(language, navigate)}
                                                                    key={language}
                                                                    href="#"
                                                                >

                                                                    {languageTranslations[language]}
                                                                </Dropdown.Item>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <li className={'item'}>
                                        <a  className={user_is_auth ? "d-flex align-items-center bg-transparent" : "bg-transparent d-flex align-items-center"}
                                                 onClick={() => user_is_auth ?navigate(i18n.language==='en'?`/todo/page/1`:`/${i18n.language}/todo/page/1`) : handleLogin()}>{user_is_auth ? t("welcomePage.btnNotAuth") : t("welcomePage.btnAuth")}</a>
                                        </li>
                                        <li className="item">
                                            <a >{t('download')}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            <section className="banner" >
                <div className="container custom-container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="banner__content">
                                <h1 className="banner__title wow fadeInLeft animated cl-3" data-wow-duration="1500ms"
                                    >{t('mainText1')}</h1>
                                <p className=" wow fadeInLeft animated cl-4" data-wow-duration="1500ms"
                                   data-wow-delay="500ms"
                                   >{t('mainText2')}</p>
                                <ul className="banner__btn-items wow fadeInLeft animated cl-5 justify-content-start" data-wow-duration="1500ms"
                                    data-wow-delay="800ms">
                                    <li><a className="" >{t('tryAppFree')}</a>
                                    </li>
                                </ul>
                                <div className="banner__user-demo wow fadeInUp animated cl-6" data-wow-duration="1500ms"
                                     data-wow-delay="1000ms"
                                    >
                                    <img src={bannerUser} alt=""/>
                                    <span><b>400k+</b> {t('mainText4')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                <div className="banner-thumb wow fadeInRight animated cl-7" data-wow-duration="1500ms"
                     data-wow-delay="1400ms"
                     >
                    <img src={bannerMobile} alt=""/>
                </div>
                <div className="banner-shape wow fadeIn animated cl-8" data-wow-duration="1500ms" data-wow-delay="1200ms"
                     >
                    <img src={bannerShape} alt=""/>
                </div>
                </div>
            </section>




            <section id={'features'} className="features mt-5">
                <div className="container custom-container">
                    <div className="row">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-6">
                            <div className="features__top-heading text-center">
                                <h3 className="features__title">{t('featuresText')}</h3>
                            </div>
                        </div>
                        <div className="col-lg-3"></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="features-item wow fadeInUp cl-11" style={{height:'270px'}} data-wow-duration="1500ms" data-wow-delay="0ms"
                                >
                                <div className="features__image-box">
                                    <img src={features1} alt=""/>
                                </div>
                                <div className="features-item__content-box">
                                    <h4 className="title">{t('feature1Main')}</h4>
                                    <p>{t('feature1Text')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="features-item wow fadeInUp cl-12" style={{height:'270px'}} data-wow-duration="1500ms"
                                 data-wow-delay="400ms"
                                >
                                <div className="features__image-box">
                                    <img src={features2} alt=""/>
                                </div>
                                <div className="features-item__content-box">
                                    <h4 className="title">{t('feature2Main')}</h4>
                                    <p>{t('feature2Text')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="features-item wow fadeInUp cl-13" style={{height:'270px'}} data-wow-duration="1500ms"
                                 data-wow-delay="800ms"
                                >
                                <div className="features__image-box">
                                    <img src={features3} alt=""/>
                                </div>
                                <div className="features-item__content-box">
                                    <h4 className="title">{t('feature3Main')}</h4>
                                    <p>{t('feature3Text')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="service" id={'service'}>
                <div className="container custom-container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="service__thumb">
                                <img className="wow fadeInUp cl-14" data-wow-duration="1500ms"
                                     src={serviceMobile1} alt=""
                                   />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="service__content wow fadeInRight cl-15" data-wow-duration="1500ms"
                                >
                                <h3 className="service__title">{t('reviewPlanning')}</h3>
                                <p>{t('review_text')}</p>
                                <a  className="btn btn-1">{t('getStartedNow')}</a>
                            </div>
                        </div>
                    </div>
                    <div className="service__item-2">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="service__content wow fadeInLeft cl-15" data-wow-duration="1500ms">
                                    <h4  className="service__title">{t('webExtention')}</h4>
                                    <p>{t('webExtentionText')}</p>

                                    <a  className="btn btn-1">{t('getStartedNow')}</a>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="service__thumb">
                                    <img className=" wow fadeInUp cl-15" data-wow-duration="1500ms"
                                         src={serviceMobile2} alt=""
                                         />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/*<section className="work">*/}
            {/*    <div className="container custom-container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-lg-12">*/}
            {/*                <div className="work__top-heading text-center">*/}
            {/*                    <h2 className="title">How app works?</h2>*/}
            {/*                </div>*/}
            {/*                <div className="work__play-box text-center">*/}
            {/*                    <img className=" wow fadeInUp cl-17" data-wow-duration="1500ms" data-wow-delay="0ms"*/}
            {/*                         src={playMobile} alt=""*/}
            {/*                         />*/}
            {/*                    <div className="work__play-button">*/}
            {/*                        <button className="video-popup" data-video-id="onkMCTdBYfU" href="#"><img*/}
            {/*                            src={playIcon} alt=""/>*/}
            {/*                        </button>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}


            <section className="process">
                <div className="container custom-container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="process__item wow fadeInUp cl-18" data-wow-duration="1500ms" data-wow-delay="0ms"
                                 >
                                <span className="process__number">1</span>
                                <h4 className="process__title">{t('installApp')}</h4>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="process__item wow fadeInUp cl-19" data-wow-duration="1500ms"
                                 data-wow-delay="300ms"
                                 >
                                <span className="process__number">2</span>
                                <h4 className="process__title">{t('createAnAccount')}</h4>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="process__item wow fadeInUp cl-20" data-wow-duration="1500ms"
                                 data-wow-delay="600ms"
                                >
                                <span className="process__number">3</span>
                                <h4 className="process__title">{t('makeInspections')}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/*<section className="testimonial">*/}
            {/*    <div className="container custom-container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-lg-6 col-md-7">*/}
            {/*                <div className="testimonial__top-title">*/}
            {/*                    <h3 className="title">Trusted by millions <br/>of customers.</h3>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-lg-6 col-md-5">*/}
            {/*                <div className="testimonial__review text-center text-md-end">*/}
            {/*                    <img src={reviewThumb} alt=""/>*/}
            {/*                    <span className="testimonial__review-text">4.5/5 (25 avis)</span>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-lg-4">*/}
            {/*                <div className="testimonial__item wow fadeInUp cl-21" data-wow-duration="1500ms"*/}
            {/*                     data-wow-delay="0ms"*/}
            {/*                   >*/}
            {/*                    <div className="testimonial__author">*/}
            {/*                        <img src={testimonialIcon1}*/}
            {/*                             alt=""/>*/}
            {/*                        <h5 className="title">Gabe Oswald</h5>*/}
            {/*                        <span>Lead Designers @Nike</span>*/}
            {/*                    </div>*/}
            {/*                    <p>“Excepteur sint occaecat cupidat non proident sunt in culpam qui the officia the anim*/}
            {/*                        id and much more.”</p>*/}
            {/*                </div>*/}
            {/*                <div className="testimonial__item wow fadeInUp cl-22" data-wow-duration="1500ms"*/}
            {/*                     data-wow-delay="0ms"*/}
            {/*                     >*/}
            {/*                    <div className="testimonial__author">*/}
            {/*                        <img src={testimonialIcon2}*/}
            {/*                             alt=""/>*/}
            {/*                        <h5 className="title">Gabe Oswald</h5>*/}
            {/*                        <span>Lead Designers @Nike</span>*/}
            {/*                    </div>*/}
            {/*                    <p>“Excepteur sint occaecat 🙏 cupidat non proident sunt in culpam qui the officia the*/}
            {/*                        anim id and much more." proident sunt in culpam qui the officia the anim id and much*/}
            {/*                        more. <br/><br/>officia the anim id and much more."</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-lg-4">*/}
            {/*                <div className="testimonial__item wow fadeInUp cl-23" data-wow-duration="1500ms"*/}
            {/*                     data-wow-delay="300ms"*/}
            {/*                     >*/}
            {/*                    <div className="testimonial__author">*/}
            {/*                        <img src={testimonialIcon2}*/}
            {/*                             alt=""/>*/}
            {/*                        <h5 className="title">Gabe Oswald</h5>*/}
            {/*                        <span>Lead Designers @Nike</span>*/}
            {/*                    </div>*/}
            {/*                    <p>Excepteur sint occaecat cupidat non proident sunt in culpam qui the officia the anim*/}
            {/*                        id and much more.*/}
            {/*                        <br/><br/>Excepteur sint occaecat cupidat non proident sunt in culpam qui the*/}
            {/*                        officia*/}
            {/*                        the anim id and much more. ❤️*/}
            {/*                    </p>*/}
            {/*                </div>*/}
            {/*                <div className="testimonial__item wow fadeInUp cl-24" data-wow-duration="1500ms"*/}
            {/*                     data-wow-delay="300ms"*/}
            {/*                    >*/}
            {/*                    <div className="testimonial__author">*/}
            {/*                        <img src={testimonialIcon3}*/}
            {/*                             alt=""/>*/}
            {/*                        <h5 className="title">Gabe Oswald</h5>*/}
            {/*                        <span>Lead Designers @Nike</span>*/}
            {/*                    </div>*/}
            {/*                    <p>“Excepteur sint occaecat 🙏 cupidat non proident sunt in culpam qui the officia the*/}
            {/*                        anim id and much more." proident sunt in culpam qui the officia the anim id and much*/}
            {/*                        more. <br/><br/>officia the anim id and much more."</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-lg-4">*/}
            {/*                <div className="testimonial__item wow fadeInUp cl-25" data-wow-duration="1500ms"*/}
            {/*                     data-wow-delay="600ms"*/}
            {/*                     >*/}
            {/*                    <div className="testimonial__author">*/}
            {/*                        <img src={testimonialIcon2}*/}
            {/*                             alt=""/>*/}
            {/*                        <h5 className="title">Gabe Oswald</h5>*/}
            {/*                        <span>Lead Designers @Nike</span>*/}
            {/*                    </div>*/}
            {/*                    <p>“Excepteur sint occaecat 🙏 cupidat non proident sunt in culpam qui the officia the*/}
            {/*                        anim id and much more." proident sunt in culpam qui the officia the anim id and much*/}
            {/*                        more. <br/><br/>officia the anim id and much more."</p>*/}
            {/*                </div>*/}
            {/*                <div className="testimonial__item wow fadeInUp cl-26" data-wow-duration="1500ms"*/}
            {/*                     data-wow-delay="600ms"*/}
            {/*                 >*/}
            {/*                    <div className="testimonial__author">*/}
            {/*                        <img src={testimonialIcon3}*/}
            {/*                             alt=""/>*/}
            {/*                        <h5 className="title">Gabe Oswald</h5>*/}
            {/*                        <span>Lead Designers @Nike</span>*/}
            {/*                    </div>*/}
            {/*                    <p>“Excepteur sint occaecat cupidat non proident sunt in culpam qui the officia the anim*/}
            {/*                        id and much more.”</p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}


            {/*<section className="price pricing-one">*/}
            {/*    <div className="container custom-container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-lg-12">*/}
            {/*                <div className="price__top-title text-center">*/}
            {/*                    <h3 className="title">Make Applounch your own <br/> with custom stuff</h3>*/}
            {/*                    <ul className="list-inline text-center switch-toggler-list" role="tablist"*/}
            {/*                        id="switch-toggle-tab">*/}
            {/*                        /!*<li className="month active"><a >Pay*!/*/}
            {/*                        /!*    Monthly</a></li>*!/*/}
            {/*                        /!*<li>*!/*/}
            {/*                        /!*    <label className="switch on">*!/*/}
            {/*                        /!*        <span className="slider round"></span>*!/*/}
            {/*                        /!*    </label>*!/*/}
            {/*                        /!*</li>*!/*/}
            {/*                        /!*<li className="year"><a >Pay Yearly</a>*!/*/}
            {/*                        /!*</li>*!/*/}
            {/*                    </ul>*/}
            {/*                </div>*/}
            {/*                <div className="tabed-content">*/}
            {/*                    <div id="month">*/}
            {/*                        <div className="row justify-content-center">*/}
            {/*                            <div className="col-lg-4 col-md-7">*/}
            {/*                                <div className="pricing-one__single fadeIn">*/}
            {/*                                    <h4 className="pricing-one__price">Free</h4>*/}
            {/*                                    <span className="pricing-one__plan">Starter</span>*/}
            {/*                                    <ul className="pricing-one__list">*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/> Online transactions</a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/> ATH withdrawals</a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/> Easy control</a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/>*/}
            {/*                                            <del>Location-based security</del>*/}
            {/*                                        </a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/>*/}
            {/*                                            <del>Online Banking</del>*/}
            {/*                                        </a></li>*/}
            {/*                                    </ul>*/}
            {/*                                    <a className="pricing-one__btn btn-1"*/}
            {/*                                       >Choose Plan</a>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                            <div className="col-lg-4 col-md-7">*/}
            {/*                                <div className="pricing-one__single pricing-one__single--item2 fadeIn">*/}
            {/*                                    <h4 className="pricing-one__price">$49</h4>*/}
            {/*                                    <p>Per month</p>*/}
            {/*                                    <span className="pricing-one__plan">Premium</span>*/}
            {/*                                    <ul className="pricing-one__list">*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/> Online transactions</a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/> ATH withdrawals</a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/> Easy control</a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/>*/}
            {/*                                            <del>Location-based security</del>*/}
            {/*                                        </a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/>*/}
            {/*                                            <del>Online Banking</del>*/}
            {/*                                        </a></li>*/}
            {/*                                    </ul>*/}
            {/*                                    <a className="pricing-one__btn btn-1"*/}
            {/*                                       >Choose Plan</a>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                            <div className="col-lg-4 col-md-7">*/}
            {/*                                <div className="pricing-one__single pricing-one__single--item3 fadeIn">*/}
            {/*                                    <h4 className="pricing-one__price">$99</h4>*/}
            {/*                                    <p>Per month</p>*/}
            {/*                                    <span className="pricing-one__plan">Ultimate</span>*/}
            {/*                                    <ul className="pricing-one__list">*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/> Online transactions</a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/> ATH withdrawals</a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/> Easy control</a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/>*/}
            {/*                                            <del>Location-based security</del>*/}
            {/*                                        </a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/>*/}
            {/*                                            <del>Online Banking</del>*/}
            {/*                                        </a></li>*/}
            {/*                                    </ul>*/}
            {/*                                    <a className="pricing-one__btn btn-1"*/}
            {/*                                       >Choose Plan</a>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div id="year" className={'cl-2'} >*/}
            {/*                        <div className="row justify-content-center">*/}
            {/*                            <div className="col-lg-4 col-md-7">*/}
            {/*                                <div className="pricing-one__single fadeIn">*/}
            {/*                                    <h4 className="pricing-one__price">Free</h4>*/}
            {/*                                    <span className="pricing-one__plan">Starter</span>*/}
            {/*                                    <ul className="pricing-one__list">*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/> Online transactions</a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/> ATH withdrawals</a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/> Easy control</a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/>*/}
            {/*                                            <del>Location-based security</del>*/}
            {/*                                        </a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/>*/}
            {/*                                            <del>Online Banking</del>*/}
            {/*                                        </a></li>*/}
            {/*                                    </ul>*/}
            {/*                                    <a className="pricing-one__btn btn-1"*/}
            {/*                                       >Choose Plan</a>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                            <div className="col-lg-4 col-md-7">*/}
            {/*                                <div className="pricing-one__single pricing-one__single--item2 fadeIn">*/}
            {/*                                    <h4 className="pricing-one__price">$149</h4>*/}
            {/*                                    <p>Per Year</p>*/}
            {/*                                    <span className="pricing-one__plan">Premium</span>*/}
            {/*                                    <ul className="pricing-one__list">*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/> Online transactions</a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/> ATH withdrawals</a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/> Easy control</a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/>*/}
            {/*                                            <del>Location-based security</del>*/}
            {/*                                        </a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/>*/}
            {/*                                            <del>Online Banking</del>*/}
            {/*                                        </a></li>*/}
            {/*                                    </ul>*/}
            {/*                                    <a className="pricing-one__btn btn-1"*/}
            {/*                                       >Choose Plan</a>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                            <div className="col-lg-4 col-md-7">*/}
            {/*                                <div className="pricing-one__single pricing-one__single--item3 fadeIn">*/}
            {/*                                    <h4 className="pricing-one__price">$199</h4>*/}
            {/*                                    <p>Per Year</p>*/}
            {/*                                    <span className="pricing-one__plan">Ultimate</span>*/}
            {/*                                    <ul className="pricing-one__list">*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/> Online transactions</a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/> ATH withdrawals</a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/> Easy control</a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/>*/}
            {/*                                            <del>Location-based security</del>*/}
            {/*                                        </a></li>*/}
            {/*                                        <li><a ><img*/}
            {/*                                            src={checkPng}*/}
            {/*                                            alt=""/>*/}
            {/*                                            <del>Online Banking</del>*/}
            {/*                                        </a></li>*/}
            {/*                                    </ul>*/}
            {/*                                    <a className="pricing-one__btn btn-1"*/}
            {/*                                       >Choose Plan</a>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}


            <section className="faq" id={'help'}>
                <div className="container custom-container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="faq__top-title text-center">
                                <h3 className="title">{t('helpWith')}</h3>
                            </div>
                            <div className="faq__accordion-box wow fadeInUp cl-11" data-wow-duration="1500ms"
                                 data-wow-delay="0ms"
                                 >
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button p-3" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne" aria-expanded="true"
                                                    aria-controls="collapseOne">{t('help1')}</button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show"
                                             aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="accordion-body p-3">{t('answer1')}</div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed p-3" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                    aria-expanded="false" aria-controls="collapseTwo">
                                                {t('help2')}
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse"
                                             aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div className="accordion-body p-3">{t('answer2')}</div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed p-3" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                                    aria-expanded="false" aria-controls="collapseThree">
                                                {t('help3')}
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse"
                                             aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body p-3">{t('answer3')}</div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingFour">
                                            <button className="accordion-button collapsed p-3" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                                    aria-expanded="false" aria-controls="collapseFour">
                                                {t('help4')}
                                            </button>
                                        </h2>
                                        <div id="collapseFour" className="accordion-collapse collapse"
                                             aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                            <div className="accordion-body p-3">{t('answer4')}</div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingFive">
                                            <button className="accordion-button collapsed p-3" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseFive"
                                                    aria-expanded="false" aria-controls="collapseFive">
                                                {t('help5')}
                                            </button>
                                        </h2>
                                        <div id="collapseFive" className="accordion-collapse collapse"
                                             aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                            <div className="accordion-body p-3">{t('answer5')}</div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingFive">
                                            <button className="accordion-button collapsed p-3" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#collapseSix"
                                                    aria-expanded="false" aria-controls="collapseSix">
                                                {t('help6')}
                                            </button>
                                        </h2>
                                        <div id="collapseSix" className="accordion-collapse collapse"
                                             aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                            <div className="accordion-body p-3">{t('answer6')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="cta">
                <div className="container custom-container">
                    <div className="row">
                        <div className="col-lg-5 offset-lg-1">
                            <div className="cta__content-box wow fadeInLeft cl-11" data-wow-duration="1500ms"
                                 data-wow-delay="0ms"
                                 >
                                <h3 className="title">{t('whyWait')}</h3>
                                <p>{t('downloadOurApp')}</p>
                                <div className="download-box">
                                    <span>{t('getTheApp')}</span>
                                    <ul className="list ps-0">
                                        <li className="item">
                                            <a >
                                                <img src={store1}
                                                     alt=""/>
                                            </a>
                                        </li>
                                        <li className="item">
                                            <a >
                                                <img src={store2}
                                                     alt=""/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="cta__thumb text-center text-lg-end wow fadeInRight cl-19"
                                 data-wow-duration="1500ms"
                                 data-wow-delay="300ms"
                                 >
                                <img src={ctaMobile} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <footer className="footer">
                <div className="container custom-container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="footer__about">
                                <a className={'fs-3 d-flex w-100 justify-content-center'}><img width={35}
                                    src={logo} alt=""/><span className={'ms-1 logo-text'}>InspectInfo</span></a>
                                <p>{t('membership')}</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer__nav-item footer__nav-item--pl">
                                <h5 className="title">{t('products')}</h5>
                                <ul className="list ps-0">
                                    <li className="item"><a>{t('howItWorks')}</a>
                                    </li>
                                    <li className="item"><a>{t('getTheApp')}</a>
                                    </li>
                                    <li className="item"><a>{t('terms')}</a>
                                    </li>
                                    <li className="item" ><a onClick={ ()=> navigate(i18n.language==='en'?`/extension`:`/${i18n.language}/extension`)}>{t('extension')}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2  col-md-6">
                            <div className="footer__nav-item">
                                <h5 className="title">{t('connect')}</h5>
                                <ul className="list ps-0">
                                    <li className="item"><a>{t('editorHelp')}</a>
                                    </li>
                                    <li className="item"><a>{t('liveChatting')}</a>
                                    </li>
                                    <li className="item"><a>{t('contactUs')}</a>
                                    </li>
                                    <li className="item"><a>{t('faqs')}</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2  col-md-6">
                            <div className="footer__nav-item">
                                <h5 className="title">{t('getExtention')}</h5>
                                <div style={{minWidth:150, maxWidth:300}} className="footer_item">
                                    <div className="footer-stores-badge text-center ">
                                        <a href="https://chrome.google.com/webstore/category/extensions?hl=uk" target="_blank" className="store d-flex">
                                            <img style={{borderRadius: 5}} className="appstore-original" src={extension}
                                                 width={180}
                                                 height={50} alt="appstore-logo"/>
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-2  col-md-6">
                            <div className="footer__nav-item">
                                <h5 className="title">{t('downloadInspectinfo')}</h5>
                                <div className="footer-stores-badge text-center mb-40">
                                    <a href="https://www.apple.com/ua/app-store/" className="store d-flex">
                                        <img className="appstore-original" src={appstore}
                                             width={160}
                                             height={50} alt="appstore-logo"/>
                                    </a>

                                    <a href="https://play.google.com/store/games?device=windows&hl=uk" className="store d-flex">
                                        <img className="googleplay-original"
                                             src={googleplay}
                                             width={160} height={50} alt="googleplay-logo"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </footer>


            {/*<div className="applounch-scroll-top progress-done">*/}
            {/*    <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">*/}
            {/*        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" className={'cl-27'}*/}
            {/*              ></path>*/}
            {/*    </svg>*/}
            {/*    <div className="applounch-scroll-top-icon">*/}
            {/*        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em"*/}
            {/*             viewBox="0 0 24 24" data-icon="mdi:arrow-up" className="iconify iconify--mdi">*/}
            {/*            <path fill="currentColor"*/}
            {/*                  d="M13 20h-2V8l-5.5 5.5l-1.42-1.42L12 4.16l7.92 7.92l-1.42 1.42L13 8v12Z"></path>*/}
            {/*        </svg>*/}
            {/*    </div>*/}
            {/*</div>*/}


            </div>
       )

}

export default Main;
