import Http from "./http";
import i18n from "i18next";

const Logout = (navigate, dispatch) => {
    Http.get('logout?').then(result => {
        console.log(result)
        localStorage.removeItem('auth_token')
        dispatch({type: 'user_is_auth', payload: false})
        navigate(i18n.language === "en" ? `/` : `/${i18n.language}/`)
    })
}

export default Logout