import modalStyle from "../TodoCard/TodoCard.module.css";
import {TextInput} from "../TextInput/TextInput";
import InputMask from "react-input-mask";
import textInputStyle from "../TextInput/TextInput.module.css";
import {Modal} from "react-bootstrap";
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {formatDate, formatTime} from "../TodoCard/TodoCard";
import i18n from "i18next";
import {handleTextChange} from "../react_hook_form/vinCodeComponent";
import {stringify} from "uuid";
import redDelete from '../../assets/img/red-delete.png'

export const TodoModal = ({showModal, setShowModal, action, title, buttonText, data = null}) => {
    const {t} = useTranslation()
    const [titleText, setTitleText] = useState(data && data.titleText ? data.titleText : '')
    const [placeText, setPlaceText] = useState(data && data.placeText ? data.placeText : '')
    const [descriptionText, setDescriptionText] = useState(data && data.descriptionText ? data.descriptionText : '')
    const [descriptionFocus, setDescriptionFocus] = useState(false)

    const [dateText, setDateText] = useState(data && data.time ? formatDate(data.time) : '')
    const [dateFocus, setDateFocus] = useState(false)
    const [dateValid, setDateValid] = useState(true)

    const [timeText, setTimeText] = useState(data && data.time ? formatTime(data.time) : '')
    const [timeFocus, setTimeFocus] = useState(false)
    const [timeValid, setTimeValid] = useState(true)

    const [tittleError, setTittleError] = useState(false)
    const [inputs, setInputs] = useState([]);
    useEffect(() => {
        if (data && data.vins) {
            const newInputs = data.vins.map((vin, index) => ({
                id: index,
                value: vin
            }));
            setInputs(newInputs);
        }
    }, [data]);
    const validateDate = useCallback((text) => {

        const currentDate = new Date()
        let enteredDate

        const [day, month, year] = text.split('.')
        const [engMonth, engDay, engYear] = text.split('.')

        const patternDDMMYYYY = /^(0[1-9]|[1-2][0-9]|3[0-1])\.(0[1-9]|1[0-2])\.(20[2-4][0-9])$/
        const patternMMDDYYYY = /^(0[1-9]|1[0-2])\.(0[1-9]|[1-2][0-9]|3[0-1])\.(20[2-4][0-9])$/


        console.log(currentDate.getFullYear() + 2)
        if (i18n.language === 'en') {
            enteredDate = new Date(engYear, engMonth - 1, engDay)
            console.log(enteredDate >= currentDate)
            console.log(enteredDate, currentDate)
            if (patternMMDDYYYY.test(text) && enteredDate >= currentDate && parseInt(engYear) <= currentDate.getFullYear() + 2) {
                setDateValid(true)
            } else {
                setDateValid(false)
            }
        } else {
            enteredDate = new Date(year, month - 1, day)
            console.log(enteredDate >= currentDate)
            console.log(enteredDate, currentDate)
            if (patternDDMMYYYY.test(text) && enteredDate >= currentDate && year <= currentDate.getFullYear() + 2) {
                setDateValid(true)
            } else {
                setDateValid(false)
            }
        }
    }, []);
    const addInput = () => {
        if (inputs.length < 5) {
            setInputs([...inputs, {id: Date.now()}]);
        }
    };

    const removeInput = (id) => {
        setInputs(inputs.filter(input => input.id !== id));
    };
    const validateTime = useCallback((text) => {

        const patternHHMM = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
        if (patternHHMM.test(text)) {
            setTimeValid(true)
        } else {
            setTimeValid(false)
        }
    }, []);
    const handleSubmit = () => {
        if (titleText === '' || titleText === null) {
            setTittleError(true)
        } else {
            const vins = getValuesArray()
            action({
                titleText: titleText,
                placeText: placeText,
                dateText: dateText,
                timeText: timeText,
                descriptionText: descriptionText,
                vins: vins
            })
        }
        console.log(getValuesArray(), typeof getValuesArray())
    }
    const handleInputChange = (id, value) => {
        const modifiedValue = handleTextChange(value);

        setInputs(inputs.map(input => input.id === id ? {...input, value: modifiedValue} : input));
    };
    const getValuesArray = () => {
        if (inputs.length > 0){
            const array = inputs.map(input => input.value);
            return JSON.stringify(array)
        }
       return []
    };

    return (
        <Modal show={showModal} className="modal" size={"lg"} centered onHide={() => setShowModal(false)}>
            <div className={modalStyle.modal_container}>


                <h2 style={{textAlign: "center"}}>{title}</h2>

                <div className={modalStyle.modal_item_container}>
                    <p className={modalStyle.p}>{t("todoPage.titleTodo")}</p>
                    <div className={modalStyle.text_input_container}>
                        <TextInput placeholder={t('todoPage.TodoNamePlaceholder')} maxWidth={1000} titleError={tittleError} setValue={setTitleText} value={titleText}/>
                    </div>

                </div>


                <div className={modalStyle.date_container}>

                    <div className={modalStyle.modal_item_container} style={{marginRight: 10}}>
                        <p className={modalStyle.p} style={{marginRight: 55}}>{t("todoPage.dateTodo")}</p>
                        <div className={modalStyle.text_input_container_date}>

                            <InputMask mask="99.99.9999" value={dateText}
                                       placeholder={'31.01.2023'}
                                       onChange={e => {
                                           setDateText(e.target.value)
                                           validateDate(e.target.value)
                                       }}
                                       onBlur={() => setDateFocus(false)}
                                       onFocus={() => setDateFocus(true)}
                                       className={dateValid ? dateFocus ? textInputStyle.input_focus : textInputStyle.input : textInputStyle.error}/>
                        </div>
                    </div>


                    <div className={modalStyle.modal_item_container}>
                        <p className={modalStyle.p}
                           style={{marginRight: 10}}>{t("todoPage.timeTodo")}</p>
                        <div className={modalStyle.text_input_container_time}>
                            <InputMask mask="99:99" value={timeText}
                                       placeholder={'11:30'}
                                       onChange={e => {
                                           setTimeText(e.target.value)
                                           validateTime(e.target.value)
                                       }}
                                       onBlur={() => setTimeFocus(false)}
                                       onFocus={() => setTimeFocus(true)}
                                       className={timeValid ? timeFocus ? textInputStyle.input_focus : textInputStyle.input : textInputStyle.error}/>
                        </div>
                    </div>
                </div>


                <div className={modalStyle.modal_item_container}>
                    <p className={modalStyle.p}>{t("todoPage.placeTodo")}</p>
                    <div className={modalStyle.text_input_container}>
                        <TextInput maxWidth={1000} setValue={setPlaceText} placeholder={t('todoPage.ReviewPlacePlaceholder')} value={placeText}/>
                    </div>
                </div>

                <div className={modalStyle.modal_item_container}>
                    <p style={{marginRight: 10, marginBottom: 0}}>{t("todoPage.bodyTodo")}</p>
                    <div className={modalStyle.text_input_container}>
                        <textarea className={descriptionFocus ? textInputStyle.input_focus : textInputStyle.input}
                                  name="descriptionArea" id="" cols="31" rows="4"
                                  value={descriptionText}
                                  onChange={(e) => setDescriptionText(e.target.value)}
                                  onBlur={() => setDescriptionFocus(false)}
                                  onFocus={() => setDescriptionFocus(true)}
                                  placeholder={t('todoPage.DescriptionPlaceholder')}
                        />
                    </div>
                </div>
                <div className={'justify-content-between align-items-start ' + modalStyle.modal_item_container}>
                   <button disabled={inputs.length>=5} className={modalStyle.addInput} onClick={addInput}>{t("todoPage.addVinInput")}</button>
                    <div className={'d-flex  flex-column justify-content-end ' + modalStyle.w80 } >
                        {inputs.map(input => (
                            <div className={'d-flex  align-items-center '} key={input.id}>
                                <TextInput maxWidth={1000} type="text" value={input.value}
                                           setValue={(newValue) => handleInputChange(input.id, newValue)}
                                placeholder={'WAUC4CF56JA038248'}/>
                                <button className={modalStyle.btnDeleteInput} onClick={() => removeInput(input.id)}><img
                                    src={redDelete} alt=""/></button>
                            </div>
                            ))}
                    </div>

                    </div>
                    <div className={modalStyle.View_btn_pos}>
                        <button onClick={handleSubmit} className={modalStyle.btnEdit}>{buttonText}</button>
                        <button className={modalStyle.btnDelete}
                                onClick={() => setShowModal(false)}>{t("mostUseText.Cancel")}</button>
                    </div>
                </div>
        </Modal>
)
}

