import React, {useEffect, useState} from 'react';
import {Controller} from "react-hook-form";
import style from "../TextInput/TextInput.module.css";
import {useTranslation} from "react-i18next";

const VinCodeComponent = ({placeholder, control, name, title, multiline = false,customErrors=null}) => {
    const [focus, setFocus] = useState(false);
    const [isEmpty, setIsEmpty] = useState(customErrors && customErrors[name]);
    const {t} = useTranslation()

    useEffect(() => {
       setIsEmpty(customErrors && customErrors[name])
    }, [customErrors]);


    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field }) => (
                <div className="input_container custom-input-margin">
                    <div className={"field_title"}>{title}{isEmpty && <span className={style.error_text}> {t("inspectionPage.requiredField")}</span>}</div>
                    <input
                        placeholder={placeholder}
                        multiple={multiline}
                        className={`${focus ? style.input_focus : style.input} ${isEmpty ? style.error : ''} custom_input`}
                        type="text"
                        value={field.value}
                        onChange={(event) => field.onChange(handleTextChange(event.target.value))}
                        onFocus={() => setFocus(true)}
                        onBlur={() => {
                            setFocus(false);
                            setIsEmpty(field.value && field.value.trim() === '');
                        }}
                    />

                </div>
            )}
        />
    );
};
export const handleTextChange = (text) => {
    const modifiedText = text
        ?.replace(/[^a-zA-Z0-9]|(\bO\b)|(\bI\b)/g, '')
        .replace(/O/gi, '0')
        .replace(/I/gi, '1')
        .toUpperCase()
        .slice(0, 17);
    return modifiedText;
};

export default VinCodeComponent;

