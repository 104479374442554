import React, {useEffect, useRef, useState} from 'react';
import style from './TodoCard.module.css'
import Http from "../../Api/http";
import {useLocation, useNavigate} from "react-router-dom";
import ModalAlert from "../Modal/Modal";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import en from "date-fns/locale/en-US";
import map from "../../assets/img/map.svg"
import Description from "../../assets/img/description.svg"
import calendar from "../../assets/img/calendar.svg"
import {TodoModal} from "../TodoModal/TodoModal";

const TodoCard = ({id, is_resolve, title,vins, description , time, opacity=false, place, setRefresh,inspectionId}) => {
    const {t} = useTranslation()

    const [showPopover, setShowPopover] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const[modalDelete,setModalDelete] = useState(false)

    const popoverRef = useRef(null)
    const dotsRef = useRef(null)
    const navigate = useNavigate();
    const location = useLocation()

    const todoId = location.state?.todo_id
    const openModal = location.state?.openModal
    const adId = location.state?.adId

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                popoverRef.current &&
                !popoverRef.current.contains(event.target) &&
                dotsRef.current &&
                !dotsRef.current.contains(event.target)
            ) {
                setShowPopover(false)
            }
        }
        if(todoId === id &&openModal){
            setShowModal(true)
        }
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                setShowPopover(false)
            }
        }

        document.addEventListener('click', handleClickOutside)
        document.addEventListener('keydown', handleEscapeKey)

        return () => {
            document.removeEventListener('click', handleClickOutside)
            document.removeEventListener('keydown', handleEscapeKey)
        }

    }, [])
    const openMap=(place)=>{
        const link =`https://www.google.com/maps/place/${place}`
        try{
            window.open(link, "_blank")
        } catch (e){
         console.log(e)
     }
 }
    const handleSubmit = (data) => {
        console.log('data', data)
        Http.post(`todos/edit/${id}?`, {title: data.titleText, shedule_date: formatDateToApi(data.dateText, data.timeText), place: data.placeText, description: data.descriptionText, vins:data.vins }).then(response => {
            console.log('response', response)
            setShowModal(false)
            setRefresh(Math.random())
        })
    }
    const ChangeIsResolve = () => {
        Http.post(`todos/edit/${id}?`, {is_resolve: !is_resolve}).then(response => {
            setShowPopover(false)
            setRefresh(Math.random())
            console.log('e', response)
        })
    }
    const DeleteTodo = () =>{
        Http.get(`todos/delete/${id}?`).then(response => {
            setShowPopover(false)
            setRefresh(Math.random())
            console.log('e', response)
        })
    }
    const handleModalDelete=()=>{
        setModalDelete(!modalDelete)
    }
    const handleSendToInpsection = ()=>{
        if(inspectionId !== null && inspectionId !== undefined){
            navigate(i18n.language==="en"?`/inspections/${inspectionId}`:`/${i18n.language}/inspections/${inspectionId}`)
        }else{
            console.log(adId)
            navigate(i18n.language==="en"?`/inspections/0/edit`:`/${i18n.language}/inspections/0/edit`,{state:{id,adId:adId}})
        }
    }

    return (
        <div className={style.card_container}>
            <ModalAlert visible={modalDelete}
                        body={t("todoPage.deleteTodo")}
                        firstBtnText={t("mostUseText.Cancel")}
                        secondBtnText={t("mostUseText.Delete")}
                        title={t("mostUseText.Deleting")}
                        firstBtnFunc={handleModalDelete}
                        secondBtnFunc={()=>{
                            DeleteTodo();
                            handleModalDelete()}}
            />
            <div style={opacity ? {opacity: '0.8'} : {}} className={style.right_side}>
                <div className={style.title_container}>
                    <div className={style.first_line_card}>{title && title}</div>
                    <div className={style.dots_container} onClick={() => setShowPopover(!showPopover)} ref={dotsRef}>
                        <span className={style.dot}></span>
                        <span className={style.dot}></span>
                        <span className={style.dot}></span>
                    </div>
                </div>

                <div className={showPopover ? style.hr_active : style.hr}/>
                {time && <div className={"d-flex mb-10 align-items-center"}>
                    <img width={21} src={calendar} style={{paddingRight: 5}} alt=""/>
                    <div className={style.text}>{t("todoPage.dateTodo")} {formatDate(time)} {formatTime(time)}</div>
                </div>}
                {place &&<div style={{cursor:'pointer'}} className={"d-flex  mb-10 align-items-start"}>
                    <img width={21} src={map}  style={{paddingRight:5,paddingTop:5}}  alt=""/>
                    <div  onClick={()=>openMap(place)} className={style.place}>{t("todoPage.placeTodo")} <span className={style.placeText}>{place}</span></div>
                </div>}
                {description && <div className={"d-flex  mb-10 align-items-start"}>
                        <img width={21} src={Description} style={{paddingRight:5,paddingTop:5}} alt=""/>
                    <div className={`${style.text} ${style.description}`}>{t("todoPage.bodyTodo")}{description}</div>
                    </div>}

            </div>
            {showPopover && (
                <div className={style.popover_container} ref={popoverRef}>
                    <div className={style.popover_item} onClick={() => {
                        setShowPopover(false)
                        setShowModal(true)
                    }}>
                        <svg width={18} style={{marginRight:5}} viewBox="0 0 24 24" stroke={'black'} fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path className={'green_icons'}
                                      d="M18.3785 8.44975L8.9636 17.8648C8.6844 18.144 8.3288 18.3343 7.94161 18.4117L4.99988 19.0001L5.58823 16.0583C5.66566 15.6711 5.85597 15.3155 6.13517 15.0363L15.5501 5.62132M18.3785 8.44975L19.7927 7.03553C20.1832 6.64501 20.1832 6.01184 19.7927 5.62132L18.3785 4.20711C17.988 3.81658 17.3548 3.81658 16.9643 4.20711L15.5501 5.62132M18.3785 8.44975L15.5501 5.62132"
                                      stroke="black" strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"></path>
                            </g>
                        </svg>
                        {t("mostUseText.Edit")}
                    </div>
                    <div onClick={handleSendToInpsection} className={style.popover_item}>
                        <svg style={{marginRight: 5}} width={17} viewBox="0 0 24 24" fill="black"
                             xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" strokeWidth="1"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path
                                    d="M16 1C17.6569 1 19 2.34315 19 4C19 4.55228 18.5523 5 18 5C17.4477 5 17 4.55228 17 4C17 3.44772 16.5523 3 16 3H4C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21H16C16.5523 21 17 20.5523 17 20V19C17 18.4477 17.4477 18 18 18C18.5523 18 19 18.4477 19 19V20C19 21.6569 17.6569 23 16 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H16Z"
                                    fill="black"></path>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M20.7991 8.20087C20.4993 7.90104 20.0132 7.90104 19.7133 8.20087L11.9166 15.9977C11.7692 16.145 11.6715 16.3348 11.6373 16.5404L11.4728 17.5272L12.4596 17.3627C12.6652 17.3285 12.855 17.2308 13.0023 17.0835L20.7991 9.28666C21.099 8.98682 21.099 8.5007 20.7991 8.20087ZM18.2991 6.78666C19.38 5.70578 21.1325 5.70577 22.2134 6.78665C23.2942 7.86754 23.2942 9.61999 22.2134 10.7009L14.4166 18.4977C13.9744 18.9398 13.4052 19.2327 12.7884 19.3355L11.8016 19.5C10.448 19.7256 9.2744 18.5521 9.50001 17.1984L9.66448 16.2116C9.76728 15.5948 10.0602 15.0256 10.5023 14.5834L18.2991 6.78666Z"
                                      fill="black"></path>
                                <path
                                    d="M5 7C5 6.44772 5.44772 6 6 6H14C14.5523 6 15 6.44772 15 7C15 7.55228 14.5523 8 14 8H6C5.44772 8 5 7.55228 5 7Z"
                                    fill="black"></path>
                                <path
                                    d="M5 11C5 10.4477 5.44772 10 6 10H10C10.5523 10 11 10.4477 11 11C11 11.5523 10.5523 12 10 12H6C5.44772 12 5 11.5523 5 11Z"
                                    fill="black"></path>
                                <path
                                    d="M5 15C5 14.4477 5.44772 14 6 14H7C7.55228 14 8 14.4477 8 15C8 15.5523 7.55228 16 7 16H6C5.44772 16 5 15.5523 5 15Z"
                                    fill="black"></path>
                            </g>
                        </svg>
                        {t("todoPage.toInspect")}
                    </div>
                    <div className={style.popover_item} onClick={() => ChangeIsResolve()}>
                        <svg width={15} style={{marginRight:5}} version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                             xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64"
                             enableBackground="new 0 0 64 64" xmlSpace="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier"> <g> <path fill="#231F20"
                                                                   d="M60,0H4C1.789,0,0,1.789,0,4v56c0,2.211,1.789,4,4,4h56c2.211,0,4-1.789,4-4V4C64,1.789,62.211,0,60,0z M58,42H45c-0.553,0-1,0.447-1,1v5H20v-5c0-0.553-0.447-1-1-1H6V6h52V42z"></path>
                                <path fill="#231F20"
                                      d="M15,16h34c0.553,0,1-0.447,1-1s-0.447-1-1-1H15c-0.553,0-1,0.447-1,1S14.447,16,15,16z"></path>
                                <path fill="#231F20"
                                      d="M15,28h34c0.553,0,1-0.447,1-1s-0.447-1-1-1H15c-0.553,0-1,0.447-1,1S14.447,28,15,28z"></path>
                                <path fill="#231F20"
                                      d="M15,34h34c0.553,0,1-0.447,1-1s-0.447-1-1-1H15c-0.553,0-1,0.447-1,1S14.447,34,15,34z"></path>
                                <path fill="#231F20"
                                      d="M15,22h34c0.553,0,1-0.447,1-1s-0.447-1-1-1H15c-0.553,0-1,0.447-1,1S14.447,22,15,22z"></path> </g> </g></svg>
                        {is_resolve === true ? <div>  {t("todoPage.fromArchive")}</div> :
                            <div>   {t("todoPage.toArchive")}</div>}
                    </div>
                    <div className={style.popover_item + ' ' + style.popover_item_delete}
                         onClick={() => handleModalDelete()}>
                        <svg style={{marginRight:5}} width={18} viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M10.3094 2.25002H13.6908C13.9072 2.24988 14.0957 2.24976 14.2737 2.27819C14.977 2.39049 15.5856 2.82915 15.9146 3.46084C15.9978 3.62073 16.0573 3.79961 16.1256 4.00494L16.2373 4.33984C16.2562 4.39653 16.2616 4.41258 16.2661 4.42522C16.4413 4.90933 16.8953 5.23659 17.4099 5.24964C17.4235 5.24998 17.44 5.25004 17.5001 5.25004H20.5001C20.9143 5.25004 21.2501 5.58582 21.2501 6.00004C21.2501 6.41425 20.9143 6.75004 20.5001 6.75004H3.5C3.08579 6.75004 2.75 6.41425 2.75 6.00004C2.75 5.58582 3.08579 5.25004 3.5 5.25004H6.50008C6.56013 5.25004 6.5767 5.24998 6.59023 5.24964C7.10488 5.23659 7.55891 4.90936 7.73402 4.42524C7.73863 4.41251 7.74392 4.39681 7.76291 4.33984L7.87452 4.00496C7.94281 3.79964 8.00233 3.62073 8.08559 3.46084C8.41453 2.82915 9.02313 2.39049 9.72643 2.27819C9.90445 2.24976 10.093 2.24988 10.3094 2.25002ZM9.00815 5.25004C9.05966 5.14902 9.10531 5.04404 9.14458 4.93548C9.1565 4.90251 9.1682 4.86742 9.18322 4.82234L9.28302 4.52292C9.37419 4.24941 9.39519 4.19363 9.41601 4.15364C9.52566 3.94307 9.72853 3.79686 9.96296 3.75942C10.0075 3.75231 10.067 3.75004 10.3553 3.75004H13.6448C13.9331 3.75004 13.9927 3.75231 14.0372 3.75942C14.2716 3.79686 14.4745 3.94307 14.5842 4.15364C14.605 4.19363 14.626 4.2494 14.7171 4.52292L14.8169 4.82216L14.8556 4.9355C14.8949 5.04405 14.9405 5.14902 14.992 5.25004H9.00815Z"
                                      fill="#ff0a0a"></path>
                                <path
                                      d="M5.91509 8.45015C5.88754 8.03685 5.53016 7.72415 5.11686 7.7517C4.70357 7.77925 4.39086 8.13663 4.41841 8.54993L4.88186 15.5017C4.96736 16.7844 5.03642 17.8205 5.19839 18.6336C5.36679 19.4789 5.65321 20.185 6.2448 20.7385C6.8364 21.2919 7.55995 21.5308 8.4146 21.6425C9.23662 21.7501 10.275 21.7501 11.5606 21.75H12.4395C13.7251 21.7501 14.7635 21.7501 15.5856 21.6425C16.4402 21.5308 17.1638 21.2919 17.7554 20.7385C18.347 20.185 18.6334 19.4789 18.8018 18.6336C18.9638 17.8206 19.0328 16.7844 19.1183 15.5017L19.5818 8.54993C19.6093 8.13663 19.2966 7.77925 18.8833 7.7517C18.47 7.72415 18.1126 8.03685 18.0851 8.45015L17.6251 15.3493C17.5353 16.6971 17.4713 17.6349 17.3307 18.3406C17.1943 19.025 17.004 19.3873 16.7306 19.6431C16.4572 19.8989 16.083 20.0647 15.391 20.1552C14.6776 20.2485 13.7376 20.25 12.3868 20.25H11.6134C10.2626 20.25 9.32255 20.2485 8.60915 20.1552C7.91715 20.0647 7.54299 19.8989 7.26958 19.6431C6.99617 19.3873 6.80583 19.025 6.66948 18.3406C6.52892 17.6349 6.46489 16.6971 6.37503 15.3493L5.91509 8.45015Z"
                                      fill="#ff0a0a"></path>
                                <path
                                      d="M9.42546 10.2538C9.83762 10.2125 10.2052 10.5133 10.2464 10.9254L10.7464 15.9254C10.7876 16.3376 10.4869 16.7051 10.0747 16.7463C9.66256 16.7875 9.29503 16.4868 9.25381 16.0747L8.75381 11.0747C8.7126 10.6625 9.01331 10.295 9.42546 10.2538Z"
                                      fill="#ff0a0a"></path>
                                <path
                                      d="M14.5747 10.2538C14.9869 10.295 15.2876 10.6625 15.2464 11.0747L14.7464 16.0747C14.7052 16.4868 14.3376 16.7875 13.9255 16.7463C13.5133 16.7051 13.2126 16.3376 13.2538 15.9254L13.7538 10.9254C13.795 10.5133 14.1626 10.2125 14.5747 10.2538Z"
                                      fill="#ff0a0a"></path>
                            </g>
                        </svg>
                        {t("mostUseText.Delete")}
                    </div>
                </div>
            )}

            {showModal && <TodoModal showModal={showModal} setShowModal={setShowModal} title={t("mostUseText.toEdit")} buttonText={t("mostUseText.Edit")} action={handleSubmit}
                                     data={{ titleText: title, placeText: place, descriptionText: description, time: time,vins:vins}}
            />}

        </div>
    )
}

export default TodoCard;
export const formatDate = (str) =>{
    let date = new Date(str)
    if (i18n.language === 'en'){
        return date.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit",  year: "numeric" }).replace(/\//g, ".")
    }else{
        return date.toLocaleDateString("ru-RU", { day: "2-digit",  month: "2-digit", year: "numeric" }).replace(/\//g, ".")
    }

}
export const formatTime = (str) =>{
    let date = new Date(str)
    return date.toLocaleTimeString("ru-RU", { hour: "2-digit", minute: "2-digit" })

}
export const formatDateToApi = (dateText, timeText) => {
    const dateParts = dateText.split('.')
    let year, month, day
    let [hours, minutes] = timeText.split(':')

    if (i18n.language === 'en'){
        year = dateParts[2]
        month = dateParts[0]
        day = dateParts[1]
    }else{
        year = dateParts[2]
        month = dateParts[1]
        day = dateParts[0]
    }

    if(hours === null || hours === undefined || hours === ''){
        hours = '00'
    }

    if(minutes === null || minutes === undefined || minutes === ''){
        minutes = '00'
    }

    console.log('zxc',`${year}-${month}-${day} ${hours}:${minutes}:00`)
    return `${year}-${month}-${day} ${hours}:${minutes}:00`
}