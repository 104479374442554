import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'
import en from "date-fns/locale/en-US";
const lang = localStorage.getItem('language')

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
    lng: lang || 'en',
    fallbackLng: ['en', 'ru', 'uk'],
    whitelist: ['en', 'ru', 'uk'],
    debug: false,
    detection: {
        order: ["queryString", "cookie"],
        cache: ["cookie"]
    },
    backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    interpolation: {
        escapeValue: false
    }
})

export default i18n