import React, {useEffect, useState} from 'react';
import style from './Paginator.module.css'
import Http from "../../Api/http";



const Paginator = ({links, limit, page, setLinks, setData, setLoading}) => {
    const currentURL = window.location.href
    const changePages = (url) => {
        console.log('url', url)
        setLoading(true)
        const searchParams = new URLSearchParams(url.split('?')[1]);
        const pageValue = searchParams.get('page');
        console.log('url', `${page}?sort=desc&page=${pageValue}&limit=${limit}&`)
        Http.get(`${page}?sort=desc&page=${pageValue}&limit=${limit}&`)
            .then(response => {
                const newURL = currentURL.replace(/\/\d+$/, `/${pageValue}`);
                window.history.pushState(null, '', newURL);
                window.location.reload()

                if (window.innerWidth < 992){
                    console.log('all links', response.links)
                    console.log(response.links.findIndex(link => link.active === true))
                    let last_links = []
                    if (response.links[response.links.length - 2].active === false){
                        last_links.push(response.links[response.links.length -2])
                    }
                    let array_of_links = [response.links[0], response.links[1], ...response.links.slice(response.links.findIndex(link => link.active === true), response.links.findIndex(link => link.active === true) + 2), ...response.links.slice(-2),]
                    setLinks([...new Set(array_of_links)])
                }else{
                    setLinks(response.links)
                }
                setData(response.data)
                setTimeout(() => {
                    setLoading(false);
                }, 500);
                console.log(response)

            })
            .catch(error => {
                console.log(error)
            })
    }
    return (
        <div className={style.pagination_container}>
            {links.map((link,index) => link.url !== null &&  <div key={index} className={link.active === true ? style.pagination_item_active : style.pagination_item} onClick={() => changePages(link.url)}>
                {link.label.includes('&laquo') ? '<' : link.label.includes('&raquo') ? '>' : link.label}
            </div>)}
        </div>
    )
}
export default Paginator