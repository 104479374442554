import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App'
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import './i18n';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import {Provider} from "react-redux";
import store from "./Redux/dispacth";
import i18n from "i18next";
import {I18nextProvider} from "react-i18next";

// const App = lazy(()=>import('./App.js'))
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <Suspense fallback={<div id="loader-wrapper">
            <div id="loader">
                <div className="cssload-spinner">
                    <div className="cssload-ball cssload-ball-1"></div>
                    <div className="cssload-ball cssload-ball-2"></div>
                    <div className="cssload-ball cssload-ball-3"></div>
                    <div className="cssload-ball cssload-ball-4"></div>
                </div>
            </div>
        </div>
        }>
            <BrowserRouter >
                <I18nextProvider i18n={i18n}>
                    <App/>
                </I18nextProvider>
            </BrowserRouter>
        </Suspense>
    </Provider>
);

reportWebVitals();
