import React, {memo, useEffect, useState} from 'react';
import style from './TodoPage.module.css';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import Http from "../../Api/http";
import TodoCard, {formatDateToApi} from "../../Components/TodoCard/TodoCard";
import Loader from "../../Components/Loader/Loader";
import Paginator from "../../Components/Paginator/Paginator";
import {useTranslation} from "react-i18next";
import plus from "../../assets/img/plus-white.png";
import i18n from "i18next";
import {TodoModal} from "../../Components/TodoModal/TodoModal";

const TodoPage = () => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [resolvedItems, setResolvedItems] = useState([]);
    const [unresolvedItems, setUnresolvedItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(0)
    const [links, setLinks] = useState([]);
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false)

    const location = useLocation()
    const todoId = location.state?.id
    const openModal = location.state?.openModal
    const adId = location.state?.adId
    const {page}=useParams()

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get('token');
        if (token !== null && token !== undefined && token !== '') {
            localStorage.setItem("auth_token", token);
            navigate(i18n.language==="en"?`/todo/page/1`:`/${i18n.language}/todo/page/1`);
        }
        if (todoId === 0 && openModal) {
            setShowModal(true)
        }
        // if (searchParams.get('todoId'))
        Http.get('user?')
            .then(response => {
                dispatch({type: 'user_info', payload: response});
                localStorage.setItem('user_info', JSON.stringify(response));
            })
            .catch(error => {
                console.log(error);
            });

    }, []);

    // useEffect(() => {
    //     if (user_is_auth === false && localStorage.getItem("auth_token")===null){
    //         const handleLogin = async () => {
    //             const url = await Login()
    //             try {
    //                 window.open(url, '_self');
    //             }catch (e){
    //                 console.log(e)
    //             }
    //         };
    //         handleLogin()
    //     }
    // }, []);

    useEffect(() => {
        Http.get(`todos?sort=desc&page=${page}&limit=${25}&`)
            .then(response => {
                    setLinks(response?.links)
                    const filteredData = response?.data
                    setData(filteredData)
                    console.log(response)
                    const resolved = filteredData?.filter(item => item.is_resolve === true)
                    const unresolved = filteredData?.filter(item => item.is_resolve === false)
                    setResolvedItems(resolved);
                    setUnresolvedItems(unresolved);
                    setLoading(false)


            })

    }, [refresh]);

    useEffect(() => {
        const resolved = data?.filter(item => item.is_resolve === true)
        const unresolved = data?.filter(item => item.is_resolve === false)
        setResolvedItems(resolved)
        setUnresolvedItems(unresolved)
    }, [links])

    const createTodo = (data) => {
        Http.post(`todos/add?`, {
            title: data.titleText,
            shedule_date: formatDateToApi(data.dateText, data.timeText),
            place: data.placeText,
            description: data.descriptionText,
            ad_id: adId !== null && adId !== undefined && adId
        }).then(response => {
            console.log('resp id', response.id)
            setShowModal(false)
            setRefresh(Math.random())
            navigate(i18n.language==="en"?`/todo/page/1`:`/${i18n.language}/todo/page/1`)
        })

    }

    if (loading) {
        return (
            <Loader/>
        )
    }

    return (
        <div>
            <div className="wrapper">
                {data?.length > 0 ?
                    <div className={style.container}>
                        {unresolvedItems.length > 0 &&
                            <div>
                                <div className={style.title_of_header}>
                                    <h2 className={style.title}>{t("todoPage.plannedTodo")}</h2>
                                    <button onClick={setShowModal} className={"custom_add_card_btn custom-btn"}>
                                        {t("mostUseText.create")}
                                        <img src={plus} width={19} style={{marginLeft: 5}} alt=""/>
                                    </button>
                                </div>
                                <div>

                                    <div className={style.cardContainer}>
                                        {unresolvedItems.map((item, index) => (
                                            <TodoCard key={index} id={item.id} is_resolve={item.is_resolve}
                                                      title={item.title}
                                                      description={item.description} time={item.shedule_date}
                                                      place={item.place}
                                                      setRefresh={setRefresh} inspectionId={item.inspection_id}
                                                      vins={JSON.parse(item?.vins)}/>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        }
                        {resolvedItems.length > 0 &&
                            <div>
                                <div className={style.title_of_header}>
                                    <h2 className={style.title}>{t("todoPage.completedTodo")}  </h2>
                                    <button onClick={setShowModal} className={"custom_add_card_btn custom-btn"}>
                                        {t("mostUseText.create")}
                                        <img src={plus} width={19} style={{marginLeft: 5}} alt=""/>
                                    </button>
                                </div>
                                <div className={style.cardContainer}>
                                    {resolvedItems.map((item, index) => (
                                        <TodoCard opacity={true} key={index} id={item.id} is_resolve={item.is_resolve}
                                                  title={item.title}
                                                  description={item.description} inspectionId={item.inspection_id}
                                                  time={item.shedule_date} place={item.place}
                                                  setRefresh={setRefresh}
                                                  vins={item.vins}
                                            />
                                    ))}
                                </div>
                            </div>
                        }
                        {links?.length > 3 &&
                            <Paginator links={links} limit={25} page={'todos'} setLinks={setLinks} setData={setData}
                                       setLoading={setLoading}/>}
                    </div>
                    :
                    <div className={"container_noData"}>
                        <div className={"d-flex flex-column align-items-center"}>
                            <div style={{fontSize: 24}} className={"mb-20 text-center"}>
                                {t("todoPage.createNewCard")}
                            </div>
                            <button onClick={()=>setShowModal(true)} className={"custom_add_card_btn custom-btn"}>
                                {t("mostUseText.create")}
                                <img src={plus} width={19} style={{marginLeft: 5}} alt=""/>
                            </button>
                        </div>
                    </div>}

                {showModal && <TodoModal showModal={showModal} setShowModal={setShowModal} title={t("todoPage.createTodo")} buttonText={t("mostUseText.create")} action={createTodo}/>}
                {/*<Modal show={showModal} className="modal" size={"lg"} centered onHide={() => setShowModal(false)}>*/}
                {/*    <div className={modalStyle.modal_container}>*/}
                {/*        <h2 style={{textAlign: "center"}}>{t("todoPage.createTodo")}</h2>*/}
                {/*        <div className={modalStyle.modal_item_container}>*/}
                {/*            <p className={modalStyle.p}>{t("todoPage.titleTodo")}</p>*/}
                {/*            <div className={modalStyle.text_input_container}>*/}
                {/*                <TextInput maxWidth={1000} setValue={setTitleText} value={titleText}/>*/}
                {/*            </div>*/}

                {/*        </div>*/}
                {/*        <div className={modalStyle.date_container}>*/}
                {/*            <div className={modalStyle.modal_item_container}>*/}
                {/*                <p className={modalStyle.p} style={{marginRight: 55}}>{t("todoPage.dateTodo")}</p>*/}
                {/*                <div className={modalStyle.text_input_container_date}>*/}

                {/*                    <InputMask mask="99.99.9999" value={dateText}*/}
                {/*                               placeholder={'31.01.2023'}*/}
                {/*                               onChange={e => setDateText(e.target.value)}*/}
                {/*                               onBlur={() => setDateFocus(false)}*/}
                {/*                               onFocus={() => setDateFocus(true)}*/}
                {/*                               className={dateFocus ? textInputStyle.input_focus : textInputStyle.input}/>*/}

                {/*                    /!*<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "en" ? en : ru}>*!/*/}
                {/*                    /!*    <div>*!/*/}
                {/*                    /!*        <DatePicker*!/*/}
                {/*                    /!*            value={dateText !== null ? dateText : new Date()}*!/*/}
                {/*                    /!*            onChange={(newValue) => setDateText(newValue)}*!/*/}
                {/*                    /!*            className={"w-100"}*!/*/}
                {/*                    /!*            minDate={new Date()}*!/*/}
                {/*                    /!*            color={"success"}*!/*/}

                {/*                    /!*        />*!/*/}
                {/*                    /!*    </div>*!/*/}
                {/*                    /!*</LocalizationProvider>*!/*/}
                {/*                </div>*/}

                {/*            </div>*/}
                {/*            <div className={modalStyle.modal_item_container}>*/}
                {/*                <p className={modalStyle.p}*/}
                {/*                   style={{marginRight: 10, marginLeft: 10}}>{t("todoPage.timeTodo")}</p>*/}
                {/*                <div className={modalStyle.text_input_container_time}>*/}
                {/*                    <InputMask mask="99:99" value={timeText}*/}
                {/*                               placeholder={'11:30'}*/}

                {/*                               onChange={e => setTimeText(e.target.value)}*/}
                {/*                               onBlur={() => setTimeFocus(false)}*/}
                {/*                               onFocus={() => setTimeFocus(true)}*/}
                {/*                               className={timeFocus ? textInputStyle.input_focus : textInputStyle.input}/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className={modalStyle.modal_item_container}>*/}
                {/*            <p className={modalStyle.p}>{t("todoPage.placeTodo")}</p>*/}
                {/*            <div className={modalStyle.text_input_container}>*/}
                {/*                <TextInput maxWidth={1000} setValue={setPlaceText} value={placeText}/>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className={modalStyle.modal_item_container}>*/}
                {/*            <p style={{marginRight: 10, marginBottom: 0}}>{t("todoPage.bodyTodo")}</p>*/}
                {/*            <div className={modalStyle.text_input_container}>*/}
                {/*                    <textarea*/}
                {/*                        className={descriptionFocus ? textInputStyle.input_focus : textInputStyle.input}*/}
                {/*                        name="descriptionArea" id="" cols="31" rows="4"*/}
                {/*                        value={descriptionText}*/}
                {/*                        onChange={(e) => setDescriptionText(e.target.value)}*/}
                {/*                        onBlur={() => setDescriptionFocus(false)}*/}
                {/*                        onFocus={() => setDescriptionFocus(true)}/>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className={modalStyle.View_btn_pos}>*/}
                {/*            <button onClick={createTodo}*/}
                {/*                    className={modalStyle.btnEdit}>{t("mostUseText.create")}</button>*/}
                {/*            <button onClick={() => {*/}
                {/*                setShowModal(false)*/}
                {/*                navigate("/todo")*/}
                {/*            }}*/}
                {/*                    className={modalStyle.btnDelete}>{t("mostUseText.Cancel")}</button>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</Modal>*/}
            </div>
        </div>
    )
}

export default memo(TodoPage)
