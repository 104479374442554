import {Link, NavLink, useNavigate} from "react-router-dom";
import '../../Pages/WelcomePage/WelcomePage.css'
import appstore from "../../assets/img/store_badges/appstore.webp";
import googleplay from "../../assets/img/store_badges/googleplay.webp";
import extension from "../../assets/img/store_badges/extension_icon.webp";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

const Footer = () => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    return (
        <footer id="footer-1" className="wide-50 footer division">
            <div className="wrapper">
                <div className='footer_container'>

                    <div style={{minWidth:180}} className="footer_item">
                        <div className="footer-links mb-20 custom-margin-footer">
                            <h5 className="h5-sm fw-bolder">{t("footer.Products")}</h5>
                            <ul className="foo-links clearfix">
                                <li><Link to={i18n.language==="en"?`/`:`/${i18n.language}/`} state={{ id: "#content-1" }}><p style={{fontSize: 16, color: 'black'}}>{t("footer.HowItWorks")}</p></Link></li>
                                <li><Link to={i18n.language==="en"?`/`:`/${i18n.language}/`} state={{ id: "#hero-6" }}><p style={{fontSize: 16, color: 'black'}}>{t("footer.GetTheApp")}</p></Link></li>
                                <li><Link onClick={()=>navigate(i18n.language==='en'?`/terms`:`/${i18n.language}/terms`)} to={i18n.language==='en'?`/terms`:`/${i18n.language}/terms`}><p style={{fontSize: 16, color: 'black'}}>{t("footer.TermsOfService")}</p></Link></li>
                                <li><Link to={i18n.language==="en"?`/extension`:`/${i18n.language}/extension`} state={{ id: "#hero-6" }}><p style={{fontSize: 16, color: 'black'}}>{t("footer.Extension")}</p></Link></li>
                            </ul>

                        </div>
                    </div>

                    <div style={{minWidth:180}} className="footer_item">
                        <div className="footer-links mb-20  custom-margin-footer">
                            <h5 className="h5-sm fw-bolder">{t("footer.Connect")}</h5>
                            <ul className="foo-links clearfix">
                                <li><a style={{color: 'black'}} href="#">{t("footer.EditorHelp")}</a></li>
                                <li><a style={{color: 'black'}} href="#">{t("footer.LifeChatting")}</a></li>
                                <li><a style={{color: 'black'}} href="#">{t("footer.ContactUs")}</a></li>
                                <li><Link to={i18n.language==="en"?`/#faqs-1`:`/${i18n.language}/#faqs-1`} state={{ id: "#faqs-1" }}><p style={{fontSize: 16, color: 'black'}}>{t("footer.FAQS")}</p></Link></li>
                            </ul>
                        </div>
                    </div>

                    <div style={{minWidth:300, maxWidth:300}} className="footer_item">
                        <div className="footer-stores-badge text-center ">
                            <h5 className="h5-sm fw-bolder">{t("footer.GetCarInspectExtention")}</h5>
                            <a href="https://chrome.google.com/webstore/category/extensions?hl=uk" target="_blank" className="store ">
                                <img style={{borderRadius: 5}} className="appstore-original" src={extension}
                                     width={180}
                                     height={50} alt="appstore-logo"/>
                            </a>
                        </div>
                    </div>

                    <div style={{minWidth:300}} className="footer_item">
                        <div className="footer-stores-badge text-center mb-40">
                            <h5 className="h5-sm fw-bolder">{t("footer.DownloadCarinspect")}</h5>
                            <a href="https://www.apple.com/ua/app-store/" className="store">
                                <img className="appstore-original" src={appstore}
                                     width={160}
                                     height={50} alt="appstore-logo"/>
                            </a>

                            <a href="https://play.google.com/store/games?device=windows&hl=uk" className="store">
                                <img className="googleplay-original"
                                     src={googleplay}
                                     width={160} height={50} alt="googleplay-logo"/>
                            </a>
                        </div>
                    </div>
                </div>


                    <div className="row">
                        <div className="col-md-12">
                            <div className="footer-copyright">
                                <p>&copy; 2025 <span>InspectInfo</span>. {t("footer.AllRightsReserved")}</p>
                            </div>
                        </div>
                    </div>


            </div>
        </footer>
    )
}
export default Footer