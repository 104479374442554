import {Base64} from 'js-base64';
const Login =(redirectUrl)=>{
    const redirect = window.location.href

    const userAgent = navigator.userAgent
    const platform = navigator.vendor
    const userData= JSON.stringify({
        redirect: redirectUrl ? redirectUrl : redirect,
        userAgent:userAgent,
        platform: platform,
    })

    const encodedJson = Base64.encode(userData)
    const url = `https://login.carinspect.org/login?params=${encodedJson}`
    return url
}
export default Login;