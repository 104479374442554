import Login from "./Login";
import store from "../Redux/dispacth";


class Http {

    async request(url, method = 'GET', data) {
        var config = {
            method,
        }

        if (method === 'POST') {
            config.body = JSON.stringify(data)
            config.headers =
                {"Accept": "application/json", 'Content-Type': 'multipart/form-data'}
        }
        const auth_token = localStorage.getItem("auth_token")
        try {
            if (auth_token) {
                const response = await fetch(`https://api.carinspect.org/${url}api_token=${auth_token}`, config)
                let result = await response.json()
                console.log('response.status', response.status)
                if (response.status === 404){
                   return {status: response.status}
                }

                if (response.status !== 200){
                    store.dispatch({type:'response_error', payload:true})
                  return 'error'
                }else{
                    return result
                }

            } else {
                const handleLogin = async () => {
                    const url = await Login()
                    try {
                        window.open(url, '_self');
                    } catch (e) {
                        console.log(e)
                    }
                };
                await handleLogin()
                store.dispatch({type:'response_error',payload:false})

            }
        } catch (e) {
            console.log('catch error ', e)
            store.dispatch({type:'response_error',payload:false})
        }
    }

    async get(url) {
        try {
            return await this.request(url, 'GET')
        } catch (e) {
            console.log(e)
        }
    }

    async post(url, data = {}) {
        try {
            return await this.request(url, 'POST', data)
        } catch (e) {
            console.log(e)
        }
    }


}

export default new Http;

