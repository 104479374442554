import React, {lazy, useEffect, useState} from 'react';
import {Routes, Route, Outlet, useNavigate, useLocation, NavLink, Router} from 'react-router-dom';
import WelcomePage from './Pages/WelcomePage/WelcomePage.jsx';
import TodoPage from './Pages/TodoPage/TodoPage';
import {useDispatch, useSelector} from 'react-redux';
import Http from "./Api/http";
import i18n, {t} from "i18next";
import './Pages/WelcomePage/spinner.css'
import './index.css'
import ButtonScrollToTop from "./Components/ScrollUpButton";
import ModalAlert from "./Components/Modal/Modal";
import {Modal} from "react-bootstrap";
import Company from "./Pages/Company";
import {useTranslation} from "react-i18next";
import {WebViewMainPage} from "./Components/WebViewMainPage";
import Main from "./Pages/WelcomePage/Main";

const TermsPage = lazy(() => import('./Pages/ProfilePage/ProfilePage'))
const ProfilePage = lazy(() => import('./Pages/ProfilePage/ProfilePage'))
const AdPage = lazy(() => import('./Pages/AdPage/AdPage'))
const InspectionsPage = lazy(() => import('./Pages/InspectionsPage/InspectionsPage'))
const AdViewPage = lazy(() => import('./Pages/AdViewPage/AdViewPage'))
const AdEditPage = lazy(() => import('./Pages/AdEditPage/AdEditPage'))
const InspectionEditPage = lazy(() => import('./Pages/InspectionEditPage/InspectionEditPage'))
const InspectionViewPage = lazy(() => import('./Pages/InspectionViewPage/InspectionViewPage'))
const Header = lazy(() => import('./Components/Header/Header'))
const Footer = lazy(() => import('./Components/Footer/Footer'))
const NotFoundPage = lazy(() => import('./Components/NotFoundPage/notFoundPage'))
const ExtensionPage = lazy(() => import('./Pages/ExtensionPage/ExtensionPage'))

const App = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user_storage_token = localStorage.getItem('auth_token');
    const [userName, setUserName] = useState(null)
    const currentLang = i18n.language
    const lang = localStorage.getItem('language')
    const location = useLocation();
    const isHomePage = location.pathname === `/${currentLang}/` || location.pathname === `/${currentLang}` || location.pathname === '/';
    const isTermsPage = location.pathname === `/${currentLang}/terms` || location.pathname === `/${currentLang}/terms/` || location.pathname === '/terms';
    const isExtensionPage = location.pathname === `/${currentLang}/extension` || location.pathname === `/${currentLang}/extension/` || location.pathname === '/extension';
    const isSharedOnlineInspections = location.pathname.includes(`/${currentLang}/online-inspections/shared`) || location.pathname.includes(`/${currentLang}/online-inspections/shared/`) || location.pathname.includes('/online-inspections/shared/');
    const isSharedInspections = location.pathname.includes(`/${currentLang}/inspections/shared`) || location.pathname.includes(`/${currentLang}/inspections/shared//`) || location.pathname.includes('/inspections/shared/');
    const pathSegments = location.pathname.split('/');
    const [isLanguageSwitched, setLanguageSwitched] = useState(false);
    const responseError = useSelector(state => state.response_error)
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        const pathSegments = location.pathname.split('/');
        const langFromUrl = pathSegments[1]; // Первый сегмент URL предполагается как код языка
        const isValidLang = ['en', 'uk', 'ru'];
        const defaultLang = 'en';

        if (isValidLang.includes(langFromUrl)) {
            localStorage.setItem('language', langFromUrl);
            i18n.changeLanguage(langFromUrl);
        } else {
            localStorage.setItem('language', defaultLang);
            i18n.changeLanguage(defaultLang);
        }

        setLanguageSwitched(true);


    }, [location.pathname]);

    useEffect(() => {
        if (responseError) {
            setShowModal(true)
        }
    }, [responseError]);


    useEffect(() => {
        user_storage_token && Http.get('user?').then(response => {
            setUserName(response?.name)
        })

        if (
            user_storage_token !== null &&
            user_storage_token !== undefined &&
            user_storage_token !== ''
        ) {
            dispatch({type: 'user_is_auth', payload: true});
        } else {
            dispatch({type: 'user_is_auth', payload: false});
        }
    }, [user_storage_token, dispatch]);
    return (
        isLanguageSwitched &&
        <div>
            <Modal show={showModal} className="modal" centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("mostUseText.error")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="grid-example">
                    {t("mostUseText.errorText")}
                </Modal.Body>
                <Modal.Footer>
                    <button className={'fw-bolder'} onClick={()=>{navigate(i18n.language === "en" ? `/` : `/${i18n.language}/`);
                        setShowModal(!showModal);
                    }}>{t("mostUseText.toMainPage")}</button>
                </Modal.Footer>
            </Modal>
            <ButtonScrollToTop/>
            {!isExtensionPage && !isHomePage && !isTermsPage && <Header userName={userName}/>}
            <div className={"main"}>
                <Routes>
                    <Route lang={currentLang}>
                        <Route path='*' element={<NotFoundPage/>}/>
                        <Route path={currentLang === "en" ? `/` : `:lang/`} element={<Main/>}/>
                        <Route path={currentLang === "en" ? `/terms` : `:lang/terms`} element={<TermsPage/>}/>
                        <Route path={currentLang === "en" ? `/extension` : `:lang/extension`}element={<ExtensionPage/>}/>
                        <Route path={currentLang === "en" ? `/todo` : `:lang/todo`} element={<TodoPage/>}/>
                        <Route path={currentLang === "en" ? `/todo/page/:page` : `:lang/todo/page/:page`}element={<TodoPage/>}/>
                        <Route path={currentLang === "en" ? `/todo/:id` : `:lang/todo/:id`} element={<TodoPage/>}/>
                        <Route path={currentLang === "en" ? `/inspections` : `:lang/inspections`} element={<InspectionsPage/>}/>
                        <Route path={currentLang === "en" ? `/inspections/shared/:alias` : `:lang/inspections/shared/:alias`} element={<InspectionViewPage shared={true}/>}/>
                        <Route path={currentLang === "en" ? `/inspections/page/:page` : `:lang/inspections/page/:page`} element={<InspectionsPage/>}/>
                        <Route path={currentLang === "en" ? `/inspections/:id` : `:lang/inspections/:id`} element={<InspectionViewPage/>}/>
                        <Route path={currentLang === "en" ? `/inspections/:id/edit` : `:lang/inspections/:id/edit`} element={<InspectionEditPage/>}/>
                        <Route path={currentLang === "en" ? `/profile` : `:lang/profile`} element={<ProfilePage/>}/>
                        <Route path={currentLang === "en" ? `/advertisement` : `:lang/advertisement`} element={<AdPage/>}/>
                        <Route path={currentLang === "en" ? `/advertisement/page/:page` : `:lang/advertisement/page/:page`} element={<AdPage/>}/>
                        <Route path={currentLang === "en" ? `/advertisement/:id` : `:lang/advertisement/:id`} element={<AdViewPage/>}/>
                        <Route path={currentLang === "en" ? `/online-inspections/shared/:alias` : `:lang/online-inspections/shared/:alias`} element={<AdViewPage shared={true}/>}/>
                        <Route path={currentLang === "en" ? `/advertisement/:id/edit` : `:lang/advertisement/:id/edit`} element={<AdEditPage/>}/>
                        <Route path={currentLang === "en" ? `/company` : `:lang/company`} element={<Company/>}/>
                        <Route path={currentLang === "en" ? `/carinspect` : `:lang/carinspect`} element={<WebViewMainPage/>}/>
                    </Route>
                </Routes>
                {!isSharedOnlineInspections && !isSharedInspections && !isExtensionPage && !isHomePage && !isTermsPage &&
                    <Footer/>}
            </div>
        </div>
    );
};

export default App;
