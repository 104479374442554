import React from 'react';
import Header from "../Header/Header";

const Loader = () => {
    return (
        <div id="loader-wrapper">
            <Header/>
            <div id="loader">
                <div className="cssload-spinner">
                    <div className="cssload-ball cssload-ball-1"></div>
                    <div className="cssload-ball cssload-ball-2"></div>
                    <div className="cssload-ball cssload-ball-3"></div>
                    <div className="cssload-ball cssload-ball-4"></div>
                </div>
            </div>
        </div>
    );
};

export default Loader;
