import React, {useState} from "react";
import style from'./TextInput.module.css'
export const TextInput = ({maxWidth = null, setValue = null, value = '', titleError=false, placeholder = ''}) =>{
    const [focus, setFocus] = useState(false)

    return(
        <div className={'w-100'}>
            <input className={titleError? style.error : focus ? style.input_focus : style.input} type="text" value={value}
                   style={ maxWidth && {maxWidth: maxWidth + 'px'}}
                   onChange={e => {
                       setValue(e.target.value)
                   }}
                   placeholder={placeholder}
                   onFocus={() => setFocus(true)}
                   onBlur={() => setFocus(false)}
            />
        </div>
    )
}