import Http from "./http";
import { v4 as uuidv4 } from 'uuid';

export function base64ToBlob(base64Data, contentType) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType })
}
export function base64VideoToBlob(base64Data, contentType) {
    const sliceSize = 1024;
    const base64WithoutHeader = base64Data.replace(/^data:video\/mp4;base64,/, '');
    const byteCharacters = atob(base64WithoutHeader);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
}
export const PostPhotoToApi = async (photo, id, url,  group_name = '', sort) => {
    return new Promise(async (resolve, reject) => {

        const blob = base64ToBlob(photo, "image/*")
        const file = new File([blob], `${uuidv4()}.jpg`, {type: "image/*"})

        const mediaObj = new FormData();
        await mediaObj.append('image', file);
        console.log('mediaObj =',mediaObj)

        console.log('sort',sort)
        // await mediaObj.append('sort',sort)
        if (group_name !== '') {
            await mediaObj.append('group_name', group_name);
        }
        if(sort){
            console.log('sort', sort)
            await mediaObj.append('sort', sort);
        }
        try {
            const api_token = localStorage.getItem("auth_token")

            const api_url = `https://api.carinspect.org/${url}/upload-files/${id}?api_token=${api_token}`


            const response = await fetch(api_url,
                {
                    method: 'post',
                    body: mediaObj,
                }
            )

            if (response.ok) {
                const result = await response.json()
                sort+=1
                console.log('Upload success', result, response.status)
                resolve()
            } else {
                console.log('Network request failed. Status:', response.status)
            }

        } catch (error) {
            console.log('Network request failed. Error:', error,photo)

        }
    })
}


const PostOrDeletePhoto = async (lastData, photo, id, group_name, maxSort = null) => {

    if (photo?.includes('base64')) {
        await PostPhotoToApi(photo, id, 'inspections', group_name, maxSort)
      const photoToDelete =  lastData?.find(photo => photo.group_name === group_name)
        if (photoToDelete){
            Http.get(`inspections/delete-files/${photoToDelete.id}?`).then(response => {
                console.log('deleted photo', response)
            })
        }

    } else if (photo === undefined || photo === null) {
        const photoToDelete = lastData?.find(photo => photo.group_name === group_name)
        if (photoToDelete !== undefined && photoToDelete !== null) {
            Http.get(`inspections/delete-files/${photoToDelete.id}?`).then(response => console.log('deleted photo', response))
        }
    }

}

const PostOrDeleteTrashPhoto = async (lastPhotos, photos, id, group_name, maxSort = null) => {
    if (lastPhotos.length > 0 && photos.length > 0){
        const removedPhotos = lastPhotos.filter(value => !photos.includes(value.filename))
        console.log('removedPhotos', id, removedPhotos)
        if (removedPhotos.length > 0){
            for (const photoToDelete of removedPhotos){
               await Http.get(`inspections/delete-files/${photoToDelete.id}?`)
            }
        }

        const newPhotos = photos.filter(photo => photo.includes('base64'))
        console.log('newPhotos', id, newPhotos)
        if (newPhotos.length > 0){
            for (const newPhoto of newPhotos){
                maxSort++
                await PostPhotoToApi(newPhoto, id, 'inspections', group_name, maxSort)
            }
        }

    }else if(photos.length === 0 || photos === undefined){
        if (lastPhotos.length > 0){
            lastPhotos.map(photoToDelete => Http.get(`inspections/delete-files/${photoToDelete.id}?`).then(response => console.log('deleted photo', response)))
        }
    }else if(lastPhotos.length === 0 || lastPhotos === undefined){
        if (photos.length > 0){
            photos.map(async photo => {
                if (photo?.includes('base64')){
                    maxSort++
                    await PostPhotoToApi(photo, id, 'inspections', group_name, maxSort)
                }
            })
        }
    }
}
const PostOrDeleteVideo = async (lastData, video, id, group_name) => {

    if (video?.includes('base64')) {
        console.log('?')
        await PostVideoToApi(video, id, 'inspections', group_name)
    } else if (video === undefined || video === null) {
        const videoToDelete = lastData?.find(video => video.group === group_name)
        if (videoToDelete !== undefined && videoToDelete !== null) {
            console.log('ewkere')
            Http.get(`inspections/delete-video/${videoToDelete.id}?`).then(response => console.log('deleted photo', response))
        }
    }

}

export const subFunctionForVideo = async (lastData, data, id) => {
    await PostOrDeleteVideo(lastData, data.transmission_video, id, 'transmission_video')
    await PostOrDeleteVideo(lastData, data.motor_video, id, 'motor_video')

}
export const subFunctionForMedia = async (lastData, data, id) => {
    let maxSort = Math.max(...lastData?.map(photo => photo.sort)) || 0;
    // maxSort++
    await PostOrDeletePhoto(lastData, data.front_left_photo, id, 'front_left_photo', maxSort++)
    // maxSort++
    await PostOrDeletePhoto(lastData, data.front_right_photo, id, 'front_right_photo', maxSort++)
    // maxSort++
    await PostOrDeletePhoto(lastData, data.front_center_photo, id, 'front_center_photo', maxSort++)
    // maxSort++
    await PostOrDeletePhoto(lastData, data.left_side_photo, id, 'left_side_photo', maxSort++)
    // maxSort++
    await PostOrDeletePhoto(lastData, data.right_side_photo, id, 'right_side_photo', maxSort++)
    // maxSort++
    await PostOrDeletePhoto(lastData, data.back_center_photo, id, 'back_center_photo', maxSort++)
    // maxSort++
    await PostOrDeletePhoto(lastData, data.back_left_photo, id, 'back_left_photo', maxSort++)
    // maxSort++
    await PostOrDeletePhoto(lastData, data.back_right_photo, id, 'back_right_photo', maxSort++)
    // maxSort++
    await PostOrDeletePhoto(lastData, data.odometer_photo, id, 'odometer_photo', maxSort++)
    // maxSort++
    await PostOrDeletePhoto(lastData, data.front_seats_photo, id, 'front_seats_photo', maxSort++)
    // maxSort++
    await PostOrDeletePhoto(lastData, data.trunk_photo, id, 'trunk_photo', maxSort++)
    // maxSort++
    await PostOrDeletePhoto(lastData, data.spare_wheels_photo, id, 'spare_wheels_photo', maxSort++)

    await PostOrDeleteTrashPhoto(lastData.filter(lastPhoto => lastPhoto.group_name === 'photos_thickness_gauge'), data.photos_thickness_gauge, id, 'photos_thickness_gauge', maxSort)
    await PostOrDeleteTrashPhoto(lastData.filter(lastPhoto => lastPhoto.group_name === 'lfp_damage'), data.lfp_damage, id, 'lfp_damage')
    await PostOrDeleteTrashPhoto(lastData.filter(lastPhoto => lastPhoto.group_name === 'results_comp_diagnostics'), data.results_comp_diagnostics, id, 'results_comp_diagnostics', maxSort)
    await PostOrDeleteTrashPhoto(lastData.filter(lastPhoto => lastPhoto.group_name === 'wheel_condition'), data.wheel_condition, id, 'wheel_condition', maxSort)
    await PostOrDeleteTrashPhoto(lastData.filter(lastPhoto => lastPhoto.group_name === 'technical_passport'), data.technical_passport, id, 'technical_passport', maxSort)
    await PostOrDeleteTrashPhoto(lastData.filter(lastPhoto => lastPhoto.group_name === 'service_book'), data.service_book, id, 'service_book')
    await PostOrDeleteTrashPhoto(lastData.filter(lastPhoto => lastPhoto.group_name === 'service_invoices'), data.service_invoices, id, 'service_invoices', maxSort)

}


export const PostOrDeleteOnlineInspectionPhotos = async (lastPhotos, photos, id) => {
    if (lastPhotos && lastPhotos.length > 0 && photos.length > 0) {
        let maxSort = Math.max(...lastPhotos.map(photo => photo.sort));

        const removedPhotos = lastPhotos.filter(value => !photos.includes(value.filename));
        console.log('removedPhotos', id, removedPhotos);

        if (removedPhotos.length > 0) {
           await removedPhotos.map(async photoToDelete  =>await Http.get(`online-inspections/delete-files/${photoToDelete.id}?`).then(response => console.log('deleted photo', response)));
        }

        const newPhotos = photos.filter(photo => photo.includes('base64'));
        console.log('newPhotos', id, newPhotos);

        if (newPhotos.length > 0) {
            let sortCounter = maxSort || 0;
           await newPhotos.map(async newPhoto => {
                sortCounter++;
                await PostPhotoToApi(newPhoto, id, 'online-inspections', '', sortCounter);
            });
        }

    } else if (photos.length === 0 || photos === undefined) {
        if (lastPhotos && lastPhotos.length > 0) {
          await  lastPhotos.map( async photoToDelete => await Http.get(`online-inspections/delete-files/${photoToDelete.id}?`).then(response => console.log('deleted photo', response)));
        }
    } else if (lastPhotos && lastPhotos.length === 0) {
        if (photos.length > 0) {
            let sortCounter = 1;
            await  photos.map(async newPhoto => {
                await PostPhotoToApi(newPhoto, id, 'online-inspections', '', sortCounter);
                sortCounter++;
            });
        }
    }
};

export const subFunctionForInsertNewMedia = async (data,id) => {
    let sort = 100
    if (data.front_left_photo){
        await PostPhotoToApi(data.front_left_photo, id, 'inspections', 'front_left_photo', sort)
    }
    if (data.front_right_photo){
        sort++
        await PostPhotoToApi(data.front_right_photo, id, 'inspections', 'front_right_photo', sort)
    }
    if (data.front_center_photo){
        sort++
        await PostPhotoToApi(data.front_center_photo, id, 'inspections', 'front_center_photo', sort)
    }
    if (data.left_side_photo){
        sort++
        await PostPhotoToApi(data.left_side_photo, id, 'inspections', 'left_side_photo', sort)
    }
    if (data.right_side_photo){
        await PostPhotoToApi(data.right_side_photo, id, 'inspections', 'right_side_photo', sort)
        sort++
    }
    if (data.back_center_photo){
        await PostPhotoToApi(data.back_center_photo, id, 'inspections', 'back_center_photo', sort)
        sort++
    }
    if (data.back_left_photo){
        await PostPhotoToApi(data.back_left_photo, id, 'inspections', 'back_left_photo', sort)
        sort++
    }
    if (data.odometer_photo){
        await PostPhotoToApi(data.odometer_photo, id, 'inspections', 'odometer_photo', sort)
        sort++
    }
    if (data.front_seats_photo){
        await PostPhotoToApi(data.front_seats_photo, id, 'inspections', 'front_seats_photo', sort)
        sort++
    }
    if (data.trunk_photo){
        await PostPhotoToApi(data.trunk_photo, id, 'inspections', 'trunk_photo', sort)
        sort++
    }
    if (data.spare_wheels_photo){
        await PostPhotoToApi(data.spare_wheels_photo, id, 'inspections', 'spare_wheels_photo', sort)
        sort++
    }
    if (data.back_right_photo){
        await PostPhotoToApi(data.back_right_photo, id, 'inspections', 'back_right_photo', sort)
        sort++
    }
    if (data.photos_thickness_gauge?.length > 0){
        data.photos_thickness_gauge.map(async photo => {
            await PostPhotoToApi(photo, id, 'inspections', 'photos_thickness_gauge')
        })
    }
    if (data.lfp_damage?.length > 0){
        data.lfp_damage.map(async photo => {
            await PostPhotoToApi(photo, id, 'inspections', 'lfp_damage')
        })
    }
    if (data.results_comp_diagnostics?.length > 0){
        data.results_comp_diagnostics.map(async photo => {
            await PostPhotoToApi(photo, id, 'inspections', 'results_comp_diagnostics')
        })
    }
    if (data.wheel_condition?.length > 0){
        data.wheel_condition.map(async photo => {
            await PostPhotoToApi(photo, id, 'inspections', 'wheel_condition')
        })
    }
    if (data.technical_passport?.length > 0){
        data.technical_passport.map(async photo => {
            await PostPhotoToApi(photo, id, 'inspections', 'technical_passport')
        })
    }
    if (data.service_book?.length > 0){
        data.service_book.map(async photo => {
            await PostPhotoToApi(photo, id, 'inspections', 'service_book')
        })
    }
    if (data.service_invoices?.length > 0){
        data.service_invoices.map(async photo => {
            await PostPhotoToApi(photo, id, 'inspections', 'service_invoices')
        })
    }
    console.log('qwe')
    if(data.transmission_video){
        await PostVideoToApi(data.transmission_video, id, 'inspections', 'transmission_video')
    }
    if(data.motor_video){
        await PostVideoToApi(data.motor_video, id, 'inspections', 'motor_video')
    }
}
export const PostVideoToApi = async (video, id, url,  group_name = '', ) => {
    return new Promise(async (resolve, reject) => {

        const blob = base64VideoToBlob(video, "video/*")
        const file = new File([blob], `${uuidv4()}.mp4`, {type: "video/*"})

        const mediaObj = new FormData();
        await mediaObj.append('video', file);
        console.log('mediaObj =',mediaObj)

        if (group_name !== '') {
            await mediaObj.append('group', group_name);
        }

        try {
            const api_token = localStorage.getItem("auth_token")

            const api_url = `https://api.carinspect.org/${url}/upload-video/${id}?api_token=${api_token}`


            const response = await fetch(api_url,
                {
                    method: 'post',
                    body: mediaObj,
                }
            )

            if (response.ok) {
                const result = await response.json()
                console.log('Upload success', result, response.status)
                resolve()
            } else {
                console.log('Network request failed. Status:', response.status)
            }

        } catch (error) {
            console.log('Network request failed. Error:', error,video)

        }
    })
}