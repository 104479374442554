import React, {useEffect, useState} from 'react';
import { Modal } from 'react-bootstrap';
import style from './modal.module.css';
import ClipboardJS from 'clipboard';
import Http from "../../Api/http";
import i18n, {t} from "i18next";

const ModalAlert = ({title, visible, body, firstBtnFunc, secondBtnFunc, firstBtnText, secondBtnText, linkShare = false, id,ad=false, secondBtnColor = null, aliasRespone}) => {
    const [showCopyMessage, setShowCopyMessage] = useState(false);
    const [alias,setAlias] = useState('')

    useEffect(() => {
        if(aliasRespone !== "" && aliasRespone !== undefined && aliasRespone !== null){
            setAlias(aliasRespone)
        }
        if (linkShare) {
            const clipboard = new ClipboardJS('.share_input');

            clipboard.on('success', (e) => {
                console.log('Text copied to clipboard:', e.text);
                setShowCopyMessage(true);
                e.clearSelection();
            });

            return () => {
                clipboard.destroy();
            };
        }
    }, [linkShare,aliasRespone]);

    const getAlias =()=>{
        Http.get(`${ad?`online-`:""}inspections/publish/${id}?`).then(response=>{
            console.log(response)
            setAlias(response.alias)
        }).catch(e => {
            console.log(e)
        })
    }
    const hideCopyMessage = () => {
        setShowCopyMessage(false);

    };

    return (
        <Modal show={visible} className="modal" centered>
            <div className="modal-header">
                <h2 className={"mb-0"}>{title}</h2>
            </div>
            <div style={linkShare?{minWidth:400}: null} className="modal-body d-flex flex-column align-items-center">
                {body}
                {linkShare && alias==="" &&<button className={'alias_generate_btn'} onClick={getAlias}>
                    create link
                </button>
                }
                {linkShare && alias!==""&&(
                    <div className="copy-container">
                        {t("inspectionPage.share_text")}
                        <button
                            className="share_input"
                            data-clipboard-action="copy"
                            data-clipboard-text={ad?`https://inspectinfo.com/${i18n.language ==="en"?"":i18n.language+"/"}online-inspections/shared/${alias}`:`https://carinspect.org/${i18n.language ==="en"?"":i18n.language+"/"}inspections/shared/${alias}`}
                            onClick={hideCopyMessage}
                        >
                            {ad?`https://inspectinfo.com/${i18n.language ==="en"?"":i18n.language+"/"}online-inspections/shared/${alias}`:`https://carinspect.org/${i18n.language ==="en"?"":i18n.language+"/"}inspections/shared/${alias}`}
                        </button>
                        {showCopyMessage && <div className="copy-message">{t("onlineInspection.LinkCopied")}</div>}
                    </div>

                )}
            </div>
            <div className="modal-footer">
                <button className={style.firstBtn} style={linkShare?{fontWeight:500}:null} onClick={firstBtnFunc}>
                    {firstBtnText}
                </button>
                {!linkShare&& secondBtnText&&
                <button className={style.secondBtn}  style={secondBtnColor ? { color: secondBtnColor } : {}} onClick={secondBtnFunc}>
                    {secondBtnText}
                </button>}
            </div>
        </Modal>
    );
};

export default ModalAlert;
