import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import Http from "../Api/http";
import Loader from "../Components/Loader/Loader";
import noPhoto from '../assets/img/upload.png'
import arrow_show from '../assets/img/dropdown-arrow-show.svg'
import arrow_hide from '../assets/img/dropdown-arrow-hide.svg'
import {v4 as uuidv4} from "uuid";
import ModalAlert from "../Components/Modal/Modal";
import {base64ToBlob} from "../Api/MediaFunctions";
import i18n from "i18next";
import {useNavigate} from "react-router-dom";


const Company = () => {
    const {t} = useTranslation()
    const fileInputRef = useRef(null);
    let mediaObj
    const [company, setCompany] = useState({})
    const [companyName, setCompanyName] = useState('')
    const [companyAddress, setCompanyAddress] = useState('')
    const [companyPhone, setCompanyPhone] = useState('')
    const [companyPerson, setCompanyPerson] = useState('')
    const [companyImg, setCompanyImg] = useState('')
    const [removeUserById, setRemoveUserById] = useState({})
    const [deleteUserModal, setDeleteUserModal] = useState(false)
    const [errorNumberMessage, setErrorNumberMessage] = useState(0);
    const [userIdError, setUserIdError] = useState(0);
    const [inviteModal, setInviteModal] = useState(false)
    const [inviteEmail, setInviteEmail] = useState('')
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [loading, setLoading] = useState(true)
    const [userInfo, setUserInfo] = useState({})

    const [showAllEmployees, setShowAllEmployees] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        Http.get('user?')
            .then(response => {
                setUserInfo(response)
                setCompany(response?.company)
                setCompanyName(response?.company?.name)
                setCompanyAddress(response?.company?.address)
                setCompanyPhone(response?.company?.phone_number)
                setCompanyPerson(response?.company?.contact_person)
                setCompanyImg(response?.company?.logo)
                setLoading(false)
                console.log(response)
            })
            .catch(error => {
                console.log(error);
            });
    }, []);
    console.log(company)

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                setCompanyImg(reader.result);
                console.log(reader);
            };
            reader.readAsDataURL(file);
        }
    };
    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleRemoveMember = (id) => {
        Http.get(`companies/remove-user/${id.id}?`).then(response => {
            console.log(response)
            Http.get('user?').then(response => {
                setCompany(response.company)
                setDeleteUserModal(!deleteUserModal)

            })
        }).catch(e => {
            console.log(e)
        })
    }
    const ErrorMessage = () => {
        return (
            <div className={"ml-20"}>
                {errorNumberMessage === 1 && (
                    <div className={'mb-10  invite-msg'}>
                        <span className={'fw-semibold fs-6 '} style={{color: 'red'}}>
                            {t("profile.userExists")}
                        </span>
                    </div>
                )}

                {errorNumberMessage === 2 && (
                    <div>
                        <span>{t("profile.userNotActive")}</span>
                        <div className={"mt-10 mb-10 invite-msg"}>
                            <button className={'btn_yes'} onClick={restoreUser}>{t("mostUseText.Yes")}</button>
                            <button className={'btn_no'}
                                    onClick={() => setErrorNumberMessage(0)}>{t("mostUseText.No")}</button>
                        </div>
                    </div>
                )}

                {!isValidEmail && (
                    <div>
                        <span className={'fw-semibold fs-6 '} style={{color: 'red'}}>
                            {t("profile.invalid_email")}
                        </span>
                    </div>
                )}

                {errorNumberMessage === 3 && (
                    <div className={'mb-10 invite-msg'}>
                        <span className={'fw-semibold fs-6 '} style={{color: '#11B64B'}}>
                            {t("profile.InviteSend")}
                        </span>
                    </div>
                )}
            </div>
        )
    }
    const handleInviteWorker = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(inviteEmail);

        if (isValid) {
            setIsValidEmail(true);
            Http.post('companies/send-invite?', {email: inviteEmail}).then(response => {
                console.log(response)
                if (response.message === "isExist") {
                    setErrorNumberMessage(1)
                } else if (response.message === 'isNotActive') {
                    setUserIdError(response?.user_id)

                    setErrorNumberMessage(2)
                } else {
                    setErrorNumberMessage(3)
                    Http.get('user?').then(response => {
                        setCompany(response.company)
                    })
                }
            }).catch(e => {
                console.log(e)
            })

        } else {
            setIsValidEmail(false);
        }
    };

    const handleSubmit = async (e) => {
        Http.post(`companies/edit/${company?.id !== null && company?.id !== undefined ? company?.id: null}?`, {
            name: companyName,
            phone_number: companyPhone,
            address: companyAddress,
            contact_person: companyPerson,
        }).then(response => {
            console.log(response)
        }).catch(e => {
            console.log(e)
        })
        if (companyImg && companyImg !== company?.logo) {
            const blob = base64ToBlob(companyImg, "image/*")
            const file = new File([blob], `${uuidv4()}.jpg`, {type: "image/*"})
            mediaObj = new FormData();
            mediaObj.append('image', file);
            try {
                const api_token = localStorage.getItem("auth_token")

                const api_url = `https://api.carinspect.org/companies/upload-files/${company?.id}?api_token=${api_token}`


                const response = await fetch(api_url,
                    {
                        method: 'post',
                        body: mediaObj,
                    }
                )
                let result = await response.json()
                console.log(result)
            } catch (e) {
                console.log(e)
            }
        }

        navigate(i18n.language === "en" ? `/profile` : `/${i18n.language}/profile`)
    };
    const handlePhoneInputChange = (e) => {
        const cleanedNumber = e.target.value.replace(/[^0-9+]/g, '');

        if (cleanedNumber.length <= 13) {
            setCompanyPhone(cleanedNumber);
        }
    };
    const restoreUser = () => {
        Http.get(`companies/restore-user/${userIdError}?`).then(response => {
            console.log(response)
            setErrorNumberMessage(3)
        }).catch(e => {
            console.log(e)
        })
    }
    const EmployeeList = ({user, index}) => {
        return(
            <div className={`employee_list_component row ${index % 2 === 0 ? "even_row" : ""}`}>
                <div className={`col-5 ${company?.owner_id === userInfo.id ? "" : "col-6"}`}>
                    {user?.name}
                </div>
                <div className="col-6">
                    {user?.email}
                </div>
                {(company?.owner_id !== user.id && company?.owner_id === userInfo.id) && (
                    <div className="col-1 cursor-pointer">
                        <svg
                            onClick={() => {
                                setDeleteUserModal(!deleteUserModal)
                                setRemoveUserById({
                                    id: user?.id,
                                    name: user?.name
                                })
                            }} width={19} viewBox="-0.5 0 25 25" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier"
                               strokeLinecap="round"
                               strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path d="M3 21.32L21 3.32001"
                                      stroke="#ff0000"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"></path>
                                <path d="M3 3.32001L21 21.32"
                                      stroke="#ff0000"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"></path>
                            </g>
                        </svg>
                    </div>
                )}
            </div>
        )
    }

    if (loading) {
        return (
            <Loader/>
        )
    }

    return (
        <div>
            <div className={"wrapper"}>
                <ModalAlert
                    visible={deleteUserModal}
                    title={t('mostUseText.Deleting')}
                    body={t('profile.removeUser1') + " " + removeUserById.name + " " + t("profile.removeUser2") + " " + company?.name + " ?"}
                    firstBtnFunc={() => {
                        setDeleteUserModal(!deleteUserModal)
                    }}
                    firstBtnText={t('mostUseText.Cancel')}
                    secondBtnFunc={() => {
                        handleRemoveMember(removeUserById)

                    }}
                    secondBtnText={t('mostUseText.Delete')}
                />
                <ModalAlert
                    visible={inviteModal}
                    title={t('profile.invite')}
                    body={<div>
                        <input value={inviteEmail} onChange={(e) => {
                            setIsValidEmail(true)
                            setErrorNumberMessage(0)
                            setInviteEmail(e.target.value)
                        }} placeholder={"E-mail"} className={`form-control custom_input_profile input_add_workers ${!isValidEmail ? 'invalid-email' : ''}`} type="email"/>
                        <ErrorMessage/>
                    </div>}
                    firstBtnFunc={() => setInviteModal(!inviteModal)}
                    firstBtnText={t('mostUseText.Cancel')}
                    secondBtnFunc={handleInviteWorker}
                    secondBtnText={t('profile.invite')}
                    secondBtnColor={"green"}
                />

                <div className={"bg-white profile_container w-100 mt-3 p-2"}>
                    <h1 className={"company_setting_title"}>{t("profile.CompanySettings")}</h1>
                    <div style={{borderBottom: "1px solid rgb(30, 211, 66)", marginBottom: 20}}/>

                    {
                        company?.owner_id === userInfo.id ?
                            <div className={"info_container_for_owner"}>
                                <div className={'d-flex justify-content-center w-100 mb-2'}>
                                    <div className={'d-flex justify-content-start flex-column general_info_width'}>
                                        <div className="text-center">
                                            <div className={"position-relative img-preview"}>
                                                <div className={'title-img_container'}>
                                                    <span className={'title-img'}>{t("profile.companyLogo")}</span>
                                                </div>
                                                <img
                                                    id="imgPreview"
                                                    className={"img-thumbnail"}
                                                    src={companyImg || noPhoto}
                                                    alt="Preview"
                                                />
                                                <button className={"btn_photoCompany"} onClick={handleButtonClick}>{t("profile.SelectFile")}</button>
                                                <input
                                                    type="file"
                                                    style={{display: 'none'}}
                                                    ref={fileInputRef}
                                                    onChange={handleFileChange}
                                                />
                                            </div>
                                        </div>
                                        <div className={'d-flex inputs_container mt-25'}>
                                            <div className={"w-50 mt-10 mr-35 input_company_profile"}>
                                                <div className={'text-start'} style={{color: "rgb(77 77 77)"}}>
                                                    {t("profile.companyName")}
                                                </div>
                                                <input
                                                    max={255}
                                                    value={companyName}
                                                    onChange={(e) => {
                                                        setCompanyName(e.target.value)
                                                    }}
                                                    style={{boxShadow: "none", outline: "none"}}
                                                    className={"form-control custom_input_profile"}
                                                    type="text"/>
                                            </div>
                                            <div className={"w-50  mt-10 input_company_profile"}>
                                                <div className={'text-start'}
                                                     style={{color: "rgb(77 77 77)"}}>{t('profile.companyPhone')}</div>
                                                <input value={companyPhone} max={255} onChange={(e) => {
                                                    handlePhoneInputChange(e)
                                                }} style={{boxShadow: "none", outline: "none"}}
                                                       className={"form-control custom_input_profile "} type="tel"/>
                                            </div>
                                        </div>
                                        <div className={'d-flex inputs_container'}>
                                            <div className={"w-50 mr-35 mt-10 input_company_profile"}>
                                                <div className={'text-start'} style={{color: "rgb(77 77 77)"}}>{t('profile.companyPerson')}</div>
                                                <input value={companyPerson} onChange={(e) => setCompanyPerson(e.target.value)} style={{boxShadow: "none", outline: "none"}}
                                                       className={"form-control custom_input_profile "} max={255} type="text"/>
                                            </div>
                                            <div className={"w-50  mt-10  input_company_profile"}>
                                                <div className={'text-start'}
                                                     style={{color: "rgb(77 77 77)"}}>{t('profile.companyAddress')}</div>
                                                <input max={255} value={companyAddress} onChange={(e) => {
                                                    setCompanyAddress(e.target.value)
                                                }} style={{boxShadow: "none", outline: "none"}}
                                                       className={"form-control custom_input_profile"} type="text"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            <div className={"info_container"}>
                                <div className={"general_info"}>
                                    <div className={"companyImg"}>
                                        {
                                            companyImg ? <img className={""} src={companyImg} alt="Preview"/> : <img className={"noPhoto"} src={noPhoto} alt="Preview"/>
                                        }

                                    </div>

                                    <div className={"row_container"}>
                                        <div className={"d-flex row"}>
                                            <p className={'col-3 title'}> {t("profile.companyName")}:</p>
                                            <p className={'col-9'}>{companyName || "-"}</p>
                                        </div>
                                        <div className={"d-flex row"}>
                                            <p className={'col-3 title'}> {t("profile.companyPhone")}:</p>
                                            <p className={'col-9'}>{companyPhone || "-"}</p>
                                        </div>
                                        <div className={"d-flex row"}>
                                            <p className={'col-3 title'}> {t("profile.companyPerson")}:</p>
                                            <p className={'col-9'}>{companyPerson || "-"}</p>
                                        </div>
                                        <div className={"d-flex row"}>
                                            <p className={'col-3 title'}> {t("profile.companyAddress")}:</p>
                                            <p className={'col-9'}>{companyAddress || "-"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }

                    <div className={"employee_list"}>
                        <div className={"d-flex justify-content-center align-items-center"}>
                            <p className={"company_setting_title employee"}>{t("profile.companyWorkers")}</p>
                            <div className={'add_worker_container'}>
                                {company?.owner_id === userInfo.id && <div>
                                        <div className={'d-flex align-items-center add_worker_button'}
                                             style={{cursor: "pointer", width: 'fit-content'}}
                                             onClick={() => setInviteModal(!inviteModal)}>
                                            <svg width={23} className={"mr-5"} viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
                                                   strokeLinejoin="round"></g>
                                                <g id="SVGRepo_iconCarrier">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M3 18C3 15.3945 4.66081 13.1768 6.98156 12.348C7.61232 12.1227 8.29183 12 9 12C9.70817 12 10.3877 12.1227 11.0184 12.348C11.3611 12.4703 11.6893 12.623 12 12.8027C12.3107 12.623 12.6389 12.4703 12.9816 12.348C13.6123 12.1227 14.2918 12 15 12C15.7082 12 16.3877 12.1227 17.0184 12.348C19.3392 13.1768 21 15.3945 21 18V21H15.75V19.5H19.5V18C19.5 15.5147 17.4853 13.5 15 13.5C14.4029 13.5 13.833 13.6163 13.3116 13.8275C14.3568 14.9073 15 16.3785 15 18V21H3V18ZM9 11.25C8.31104 11.25 7.66548 11.0642 7.11068 10.74C5.9977 10.0896 5.25 8.88211 5.25 7.5C5.25 5.42893 6.92893 3.75 9 3.75C10.2267 3.75 11.3158 4.33901 12 5.24963C12.6842 4.33901 13.7733 3.75 15 3.75C17.0711 3.75 18.75 5.42893 18.75 7.5C18.75 8.88211 18.0023 10.0896 16.8893 10.74C16.3345 11.0642 15.689 11.25 15 11.25C14.311 11.25 13.6655 11.0642 13.1107 10.74C12.6776 10.4869 12.2999 10.1495 12 9.75036C11.7001 10.1496 11.3224 10.4869 10.8893 10.74C10.3345 11.0642 9.68896 11.25 9 11.25ZM13.5 18V19.5H4.5V18C4.5 15.5147 6.51472 13.5 9 13.5C11.4853 13.5 13.5 15.5147 13.5 18ZM11.25 7.5C11.25 8.74264 10.2426 9.75 9 9.75C7.75736 9.75 6.75 8.74264 6.75 7.5C6.75 6.25736 7.75736 5.25 9 5.25C10.2426 5.25 11.25 6.25736 11.25 7.5ZM15 5.25C13.7574 5.25 12.75 6.25736 12.75 7.5C12.75 8.74264 13.7574 9.75 15 9.75C16.2426 9.75 17.25 8.74264 17.25 7.5C17.25 6.25736 16.2426 5.25 15 5.25Z"
                                                          fill="#000000"></path>
                                                </g>
                                            </svg>
                                            <span className={'fw-semibold'}>{t("profile.inviteWorker")}</span>
                                        </div>

                                    </div>
                                }
                            </div>
                        </div>


                        <div className={"employee_list_container"}>
                            <div className={"row employee_list_width list_header"}>
                                <div className={ company?.owner_id === userInfo.id ?  "col-5 fw-bolder" : "col-6 fw-bolder"}>
                                    {t("profile.name")}
                                </div>
                                <div className={"col-6 fw-bolder"}>
                                    E-mail
                                </div>
                                {
                                    (company?.owner_id === userInfo.id) && <div className={"col-1"}></div>
                                }
                            </div>
                            <div className={"row employee_list_width"}>
                                {
                                    company?.users?.slice(0, 5).map((user, index) => <EmployeeList user={user} index={index}/>)
                                }
                            </div>
                            { showAllEmployees && <div className={"row employee_list_width"}>
                                {
                                    company?.users?.slice(5).map((user, index) => <EmployeeList user={user} index={index}/>)
                                }
                            </div>
                            }

                            {
                                company?.users?.length > 5 && <div className={"show_more"} onClick={() => setShowAllEmployees(!showAllEmployees)}>
                                    <img src={showAllEmployees ? arrow_hide : arrow_show } width={24} height={24} alt=""/>
                                    {showAllEmployees ?  'Hide' : 'Show more' }
                                </div>
                            }

                            <div className={company?.owner_id === userInfo.id ?'d-flex w-100 justify-content-center': 'd-none '}>
                                <div className={"mt-30"}>
                                    <button onClick={handleSubmit}
                                            style={company?.owner_id === userInfo.id ? {display:"block"} : {display:'none!importnant'}}
                                            className={"CreateReviewBtn custom-btn mb-0 d-flex align-items-center"}>
                                        <svg width={15} style={{marginRight: 10}} viewBox="0 -0.5 21 21" version="1.1"
                                             xmlns="http://www.w3.org/2000/svg"
                                             xmlnsXlink="http://www.w3.org/1999/xlink" fill="#11B64B">
                                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                            <g id="SVGRepo_iconCarrier">
                                                <g id="Page-1" stroke="none" strokeWidth="1" fill="#11B64B"
                                                   fillRule="evenodd">
                                                    <g className="green_icons" id="Dribbble-Light-Preview"
                                                       transform="translate(-99.000000, -680.000000)" fill="#11B64B">
                                                        <g id="icons" transform="translate(56.000000, 160.000000)">
                                                            <path
                                                                d="M50.21875,525 L52.31875,525 L52.31875,523 L50.21875,523 L50.21875,525 Z M61.9,538 L59.8,538 L59.8,532 L58.88125,532 L57.7,532 L49.3,532 L47.5276,532 L47.2,532 L47.2,538 L45.1,538 L45.1,526.837 L47.2,524.837 L47.2,528 L48.11875,528 L49.3,528 L57.7,528 L59.47135,528 L59.8,528 L59.8,522 L61.9,522 L61.9,538 Z M49.3,538 L57.7,538 L57.7,534 L49.3,534 L49.3,538 Z M49.3,522.837 L50.17885,522 L57.7,522 L57.7,526 L49.3,526 L49.3,522.837 Z M63.9664,520 L61.8664,520 L49.3,520 L49.3,520.008 L47.2084,522 L47.2,522 L47.2,522.008 L43.0084,526 L43,526 L43,538 L43,540 L45.1,540 L61.8664,540 L63.9664,540 L64,540 L64,538 L64,522 L64,520 L63.9664,520 Z"
                                                                id="save_item-[#11B64B]"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        {t("mostUseText.save")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        // <div>
        //     <div className={"wrapper"}>
        //         <ModalAlert
        //             visible={deleteUserModal}
        //             title={t('mostUseText.Deleting')}
        //             body={t('profile.removeUser1') + " " + removeUserById.name + " " + t("profile.removeUser2") + " " + company?.name + " ?"}
        //             firstBtnFunc={() => {
        //                 setDeleteUserModal(!deleteUserModal)
        //             }}
        //             firstBtnText={t('mostUseText.Cancel')}
        //             secondBtnFunc={() => {
        //                 handleRemoveMember(removeUserById)
        //
        //             }}
        //             secondBtnText={t('mostUseText.Delete')}
        //         />
        //         <div className={"bg-white profile_container mt-3 p-2 "}>
        //             <div className={"text-center mb-10 fs-5"}>
        //                 <div className={'modal-header fw-semibold fs-4 mb-10'}>{t("profile.CompanySettings")}</div>
        //                 <div style={{borderBottom: "1px solid rgb(30, 211, 66)", marginBottom: 20}}/>
        //                 <div className={'employees_list'}>
        //                     <div className={"modal-header workers_title fs-4 mb-2"}>
        //                         {t("profile.companyWorkers")}
        //                     </div>
        //                     <div className={''}>
        //                         <div className={"row mb-20 justify-content-around"}>
        //                             <div className={"col-4 fw-bolder"}>
        //                                 {t("profile.name")}
        //                             </div>
        //                             <div className={"col-4 fw-bolder show-email"}>
        //                                 E-mail
        //                             </div>
        //                             <div className={"col-1 "}>
        //                             </div>
        //                         </div>
        //                         <div className={'workers_container'}>
        //                             {company?.users.map((user) => {
        //                                 return (
        //                                     <div>
        //                                         {user.is_active && (
        //                                             <div key={user.id}
        //                                                  className={"row row_workers justify-content-around"}>
        //                                                 <div className={"col-4"}>
        //                                                     {user?.name}
        //                                                 </div>
        //                                                 <div className={"col-4 show-email"}>
        //                                                     {user?.email}
        //                                                 </div>
        //                                                 <div className={"col-1 btn_delete_worker"}>
        //                                                     {company?.owner_id !== user.id && company?.owner_id === userInfo.id ? (
        //                                                         <svg
        //                                                             onClick={() => {
        //                                                                 setDeleteUserModal(!deleteUserModal)
        //                                                                 setRemoveUserById({
        //                                                                     id: user?.id,
        //                                                                     name: user?.name
        //                                                                 })
        //                                                             }} width={19} viewBox="-0.5 0 25 25" fill="none"
        //                                                             xmlns="http://www.w3.org/2000/svg">
        //                                                             <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        //                                                             <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
        //                                                                strokeLinejoin="round"></g>
        //                                                             <g id="SVGRepo_iconCarrier">
        //                                                                 <path d="M3 21.32L21 3.32001" stroke="#ff0000"
        //                                                                       strokeWidth="1.5"
        //                                                                       strokeLinecap="round"
        //                                                                       strokeLinejoin="round"></path>
        //                                                                 <path d="M3 3.32001L21 21.32" stroke="#ff0000"
        //                                                                       strokeWidth="1.5"
        //                                                                       strokeLinecap="round"
        //                                                                       strokeLinejoin="round"></path>
        //                                                             </g>
        //                                                         </svg>
        //                                                     ) : null}
        //                                                 </div>
        //                                             </div>
        //                                         )}
        //                                     </div>
        //                                 )
        //                             })}
        //                         </div>
        //
        //                     </div>
        //                 </div>
        //                 <div className={'add_worker_container mt-20 '}>
        //                     {company?.owner_id === userInfo.id ? (
        //                         <div>
        //                             <div className={'d-flex align-items-center ml-20 mt-10 mb-10'}
        //                                  style={{cursor: "pointer", width: 'fit-content'}}
        //                                  onClick={() => setShowInviteInput(!showInviteInput)}>
        //                                 <svg width={23} className={"mr-5"} viewBox="0 0 24 24" fill="none"
        //                                      xmlns="http://www.w3.org/2000/svg">
        //                                     <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        //                                     <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
        //                                        strokeLinejoin="round"></g>
        //                                     <g id="SVGRepo_iconCarrier">
        //                                         <path fillRule="evenodd" clipRule="evenodd"
        //                                               d="M3 18C3 15.3945 4.66081 13.1768 6.98156 12.348C7.61232 12.1227 8.29183 12 9 12C9.70817 12 10.3877 12.1227 11.0184 12.348C11.3611 12.4703 11.6893 12.623 12 12.8027C12.3107 12.623 12.6389 12.4703 12.9816 12.348C13.6123 12.1227 14.2918 12 15 12C15.7082 12 16.3877 12.1227 17.0184 12.348C19.3392 13.1768 21 15.3945 21 18V21H15.75V19.5H19.5V18C19.5 15.5147 17.4853 13.5 15 13.5C14.4029 13.5 13.833 13.6163 13.3116 13.8275C14.3568 14.9073 15 16.3785 15 18V21H3V18ZM9 11.25C8.31104 11.25 7.66548 11.0642 7.11068 10.74C5.9977 10.0896 5.25 8.88211 5.25 7.5C5.25 5.42893 6.92893 3.75 9 3.75C10.2267 3.75 11.3158 4.33901 12 5.24963C12.6842 4.33901 13.7733 3.75 15 3.75C17.0711 3.75 18.75 5.42893 18.75 7.5C18.75 8.88211 18.0023 10.0896 16.8893 10.74C16.3345 11.0642 15.689 11.25 15 11.25C14.311 11.25 13.6655 11.0642 13.1107 10.74C12.6776 10.4869 12.2999 10.1495 12 9.75036C11.7001 10.1496 11.3224 10.4869 10.8893 10.74C10.3345 11.0642 9.68896 11.25 9 11.25ZM13.5 18V19.5H4.5V18C4.5 15.5147 6.51472 13.5 9 13.5C11.4853 13.5 13.5 15.5147 13.5 18ZM11.25 7.5C11.25 8.74264 10.2426 9.75 9 9.75C7.75736 9.75 6.75 8.74264 6.75 7.5C6.75 6.25736 7.75736 5.25 9 5.25C10.2426 5.25 11.25 6.25736 11.25 7.5ZM15 5.25C13.7574 5.25 12.75 6.25736 12.75 7.5C12.75 8.74264 13.7574 9.75 15 9.75C16.2426 9.75 17.25 8.74264 17.25 7.5C17.25 6.25736 16.2426 5.25 15 5.25Z"
        //                                               fill="#000000"></path>
        //                                     </g>
        //                                 </svg>
        //                                 <span className={'fs-5 fw-semibold'}>{t("profile.inviteWorker")}</span>
        //                                 {showInviteInput ?
        //                                     <svg width={18} style={{marginTop: 4, marginLeft: 5}} viewBox="0 0 24 24"
        //                                          fill="none"
        //                                          xmlns="http://www.w3.org/2000/svg"
        //                                          transform="rotate(90)">
        //                                         <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        //                                         <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
        //                                            strokeLinejoin="round"></g>
        //                                         <g id="SVGRepo_iconCarrier">
        //                                             <path
        //                                                 d="M9 5L14.15 10C14.4237 10.2563 14.6419 10.5659 14.791 10.9099C14.9402 11.2539 15.0171 11.625 15.0171 12C15.0171 12.375 14.9402 12.7458 14.791 13.0898C14.6419 13.4339 14.4237 13.7437 14.15 14L9 19"
        //                                                 stroke="#000000" strokeWidth="1.5" strokeLinecap="round"
        //                                                 strokeLinejoin="round"></path>
        //                                         </g>
        //                                     </svg>
        //                                     :
        //                                     <svg width={18} style={{marginTop: 4, marginLeft: 5}} viewBox="0 0 24 24"
        //                                          fill="none"
        //                                          xmlns="http://www.w3.org/2000/svg">
        //                                         <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        //                                         <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
        //                                            strokeLinejoin="round"></g>
        //                                         <g id="SVGRepo_iconCarrier">
        //                                             <path
        //                                                 d="M9 5L14.15 10C14.4237 10.2563 14.6419 10.5659 14.791 10.9099C14.9402 11.2539 15.0171 11.625 15.0171 12C15.0171 12.375 14.9402 12.7458 14.791 13.0898C14.6419 13.4339 14.4237 13.7437 14.15 14L9 19"
        //                                                 stroke="#000000" strokeWidth="1.5" strokeLinecap="round"
        //                                                 strokeLinejoin="round"></path>
        //                                         </g>
        //                                     </svg>
        //                                 }
        //
        //                             </div>
        //                             {showInviteInput && (
        //                                 <div className={'d-flex mb-10 ml-20 align-items-center'}>
        //                                     <input value={inviteInput} onChange={(e) => setInviteInput(e.target.value)}
        //                                            placeholder={"E-mail"}
        //                                            className={`form-control custom_input_profile input_add_workers ${
        //                                                !isValidEmail ? 'invalid-email' : ''
        //                                            }`} type="email"/>
        //                                     <button onClick={handleInviteWorker}
        //                                             className={'ml-10 invite_worker_btn'}>{t("profile.invite")}</button>
        //                                 </div>
        //                             )}
        //                             <ErrorMessage/>
        //                         </div>
        //                     ) : null}
        //                 </div>
        //                 <div className={'d-flex custom_column_company'}>
        //                     <div className={'d-flex justify-content-center company_logo_container'}>
        //                         <div
        //                             className={'w-25 custom-photo_container mb-2 position-relative d-flex flex-column align-items-center'}>
        //                             <div className={'title-img_container'}>
        //                                 <span className={'title-img'}>{t("profile.companyLogo")}</span>
        //                             </div>
        //                             {companyImg ?
        //                                 <img src={companyImg} className={'custom-company-img-margin'} width={150}
        //                                      alt=""/>
        //                                 :
        //                                 <img src={noPhoto} style={{opacity: "0.2"}}
        //                                      className={'custom-company-img-margin'}
        //                                      width={150} alt=""/>
        //                             }
        //                             <button className={"btn_photoCompany"} disabled={company?.owner_id === userInfo.id ? false : true} onClick={handleButtonClick}>
        //                                 {t("profile.SelectFile")}
        //                             </button>
        //                             <input
        //                                 type="file"
        //                                 style={{display: 'none'}}
        //                                 ref={fileInputRef}
        //                                 onChange={handleFileChange}
        //                             />
        //                         </div>
        //                     </div>
        //                     <div className={'d-flex justify-content-center w-100 mb-2'}>
        //                         <div className={'d-flex justify-content-start flex-column w-75'}>
        //                                 <div className={" custom-margin-style mt-10 mr-35 input_company_profile"}>
        //                                     <div className={'text-start'} style={{color: "rgb(77 77 77)"}}>
        //                                         {t("profile.companyName")}
        //                                     </div>
        //                                     <input
        //                                         disabled={company?.owner_id === userInfo.id ? false : true}
        //                                         value={companyName}
        //                                         onChange={(e) => {
        //                                             setCompanyName(e.target.value)
        //                                         }}
        //                                         style={{boxShadow: "none", outline: "none"}}
        //                                         className={"form-control custom_input_profile"}
        //                                         type="text"/>
        //                                 </div>
        //                                 <div className={" custom-margin-style mt-10 input_company_profile"}>
        //                                     <div className={'text-start'}
        //                                          style={{color: "rgb(77 77 77)"}}>{t('profile.companyPhone')}</div>
        //                                     <input value={companyPhone} onChange={(e) => {
        //                                         handlePhoneInputChange(e)
        //                                     }} style={{boxShadow: "none", outline: "none"}}
        //                                            className={"form-control custom_input_profile "} disabled={company?.owner_id === userInfo.id ? false : true} type="tel"/>
        //                                 </div>
        //                             <div className={" custom-margin-style mt-10 input_company_profile"}>
        //                                 <div className={'text-start'}
        //                                      style={{color: "rgb(77 77 77)"}}>{t('profile.companyPerson')}</div>
        //                                 <input value={companyPerson} onChange={(e) => {
        //                                     setCompanyPerson(e);
        //                                 }} style={{boxShadow: "none", outline: "none"}}
        //                                        className={"form-control custom_input_profile "} disabled={company?.owner_id === userInfo.id ? false : true} type="text"/>
        //                             </div>
        //                             <div className={"d-flex justify-content-between  custom-flex-style"}>
        //                                 <div className={" custom-margin-style mt-10  input_company_profile"}>
        //                                     <div className={'text-start'}
        //                                          style={{color: "rgb(77 77 77)"}}>{t('profile.companyAddress')}</div>
        //                                     <input value={companyAddress} onChange={(e) => {
        //                                         setCompanyAddress(e.target.value)
        //                                     }} style={{boxShadow: "none", outline: "none"}}
        //                                            disabled={company?.owner_id === userInfo.id ? false : true}
        //                                            className={"form-control custom_input_profile"} type="text"/>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <div className={company?.owner_id === userInfo.id ?'d-flex w-100 justify-content-center': 'd-none '}>
        //                     <div className={"mt-30"}>
        //                         <button onClick={handleSubmit}
        //                                 style={company?.owner_id === userInfo.id ? {display:"block"} : {display:'none!importnant'}}
        //                                 className={"CreateReviewBtn custom-btn mb-0 d-flex align-items-center"}>
        //                             <svg width={15} style={{marginRight: 10}} viewBox="0 -0.5 21 21" version="1.1"
        //                                  xmlns="http://www.w3.org/2000/svg"
        //                                  xmlnsXlink="http://www.w3.org/1999/xlink" fill="#11B64B">
        //                                 <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        //                                 <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        //                                 <g id="SVGRepo_iconCarrier">
        //                                     <g id="Page-1" stroke="none" strokeWidth="1" fill="#11B64B"
        //                                        fillRule="evenodd">
        //                                         <g className="green_icons" id="Dribbble-Light-Preview"
        //                                            transform="translate(-99.000000, -680.000000)" fill="#11B64B">
        //                                             <g id="icons" transform="translate(56.000000, 160.000000)">
        //                                                 <path
        //                                                     d="M50.21875,525 L52.31875,525 L52.31875,523 L50.21875,523 L50.21875,525 Z M61.9,538 L59.8,538 L59.8,532 L58.88125,532 L57.7,532 L49.3,532 L47.5276,532 L47.2,532 L47.2,538 L45.1,538 L45.1,526.837 L47.2,524.837 L47.2,528 L48.11875,528 L49.3,528 L57.7,528 L59.47135,528 L59.8,528 L59.8,522 L61.9,522 L61.9,538 Z M49.3,538 L57.7,538 L57.7,534 L49.3,534 L49.3,538 Z M49.3,522.837 L50.17885,522 L57.7,522 L57.7,526 L49.3,526 L49.3,522.837 Z M63.9664,520 L61.8664,520 L49.3,520 L49.3,520.008 L47.2084,522 L47.2,522 L47.2,522.008 L43.0084,526 L43,526 L43,538 L43,540 L45.1,540 L61.8664,540 L63.9664,540 L64,540 L64,538 L64,522 L64,520 L63.9664,520 Z"
        //                                                     id="save_item-[#11B64B]"></path>
        //                                             </g>
        //                                         </g>
        //                                     </g>
        //                                 </g>
        //                             </svg>
        //                             {t("mostUseText.save")}
        //                         </button>
        //                     </div>
        //                 </div>
        // {/*            </div>*/}
        // {/*        </div>*/}
        // {/*    </div>*/}
        // {/*</div>*/}
    );
};

export default Company;
