import {createStore} from "redux";

const initialState = {
    lang: 'en',
    user_is_auth: false,
    user_token: '',
    user_info: {},
    response_error:false,
};

const appReducer=(state = initialState, action)=> {
    switch (action.type) {
        case 'response_error':
            return {...state, response_error: action.payload}
        case 'lang':
            return { ...state, lang: action.payload };
        case 'user_is_auth':
            return {...state, user_is_auth: action.payload}
        case 'user_info':
            return {...state, user_info: action.payload}
        case 'user_token':
            return {...state, user_token: action.payload}
        default:
            return state;
    }
}
const store = createStore(appReducer);
export default store;